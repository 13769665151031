// src/components/HostNavBar.js
import React, { useEffect } from 'react';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth, UserButton, SignOutButton, useUser } from "@clerk/clerk-react";

const HostNavBar = () => {
  const { isLoaded, userId, sessionId } = useAuth();
  const { isSignedIn, user } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    // Use useEffect to perform navigation only once when the component mounts
    if (!isSignedIn) {
      navigate('/');
    }
  }, [isSignedIn]);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">SpaceYettu</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto" navbarScroll>
            {/* Add your other navigation links here if needed */}
          </Nav>

          <Nav>
            {isSignedIn ? (
              <>
                <UserButton />
                <SignOutButton className="app-signout-button"/>
              </>
            ) : (
              <>
                <Nav.Link as={Link} to="/become-host">Become a host</Nav.Link>
                <Nav.Link as={Link} to="/signin">Sign in</Nav.Link>
                <Nav.Link as={Link} to="/signup">Sign up</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HostNavBar;
