import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaToggleOff,FaToggleOn } from "react-icons/fa6";
import "./ToggleSidebar.css";
import SpaceComponent from '../components/Dashbaord/space';
import UserProfileComponent from '../components/Dashbaord/UserProfileComponent';
import BookingList from '../components/Dashbaord/Booking';


const ToggleSidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState('space');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const renderContent = () => {
    switch (selectedOption) {
      case 'space':
        return (
          <SpaceComponent/>
        );
      case 'userprofile':
        return (
        <h2>User</h2>
        );
      case 'payments':
        return (
          <Container>
            <h2>Payments Content</h2>
          </Container>
        );
      default:
        return (
          <BookingList/>
        );
    }
  };

  return (
    <div className="toggle-sidebar">

      <div className="custom-toggle-button" >
        {sidebarVisible ? <FaToggleOff className='toggle-icon'  onClick={toggleSidebar}/> : <FaToggleOn className='toggle-icon' onClick={toggleSidebar}/>}
      </div>

      <div className={sidebarVisible ? "main-dashboard" : 'main-dashboard-2'}>

        <div className={sidebarVisible ? 'sidebar-nav' : 'sidebar-nav none'}>
          <ul>

            <li className={selectedOption === 'space' ? 'active' : ''} onClick={() => handleOptionClick('space')}>
              Space
            </li>
            <li className={selectedOption === 'dashboard' ? 'active' : ''} onClick={() => handleOptionClick('dashboard')}>
              Booking
            </li>

            <li className={selectedOption === 'userprofile' ? 'active' : ''} onClick={() => handleOptionClick('userprofile')}>
              User Profile
            </li>
            <li className={selectedOption === 'payments' ? 'active' : ''} onClick={() => handleOptionClick('payments')}>
              Payments
            </li>
          </ul>
        </div>

        <div className='dashboard-container'>
          {renderContent()}
        </div>

      </div>
    </div>
  );
};

export default ToggleSidebar;
