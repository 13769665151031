import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Tab, Tabs, Table, Modal } from 'react-bootstrap';
import SpaceAvailabilityForm from './SpaceAvailabilityForm';
import SpaceForm from "./spaceSettings/SpaceFormGeneral";
import MapSelector from '../../utilities/MapSelector';
import { toast, ToastContainer } from 'react-toastify';
import { TiWarningOutline } from "react-icons/ti";
import { IoCloudUpload } from "react-icons/io5";
import Billing from './spaceSettings/Billing';
import { useDropzone } from 'react-dropzone';
import { useUser } from "@clerk/clerk-react";
import axios from 'axios';
import "./space.css"
import LoadingComponent from '../../utilities/utilities';
import ReactPaginate from 'react-paginate';


const SpaceComponent = () => {
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [totalSpaces, setTotalSpaces] = useState(0);
  const { user } = useUser();

  // Dummy data (replace with actual data from your application)
  const totalInboxes = 0;
  const currentTokens = 0;


  useEffect(() => {

    fetchTotalSpaces();
  }, [user.id]);


  const fetchTotalSpaces = async () => {
    // Get the appUserId from the user object
    let appUserId = user.id;
  
    try {
      // Make a POST request to fetch total spaces for the provided appUserId
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/totalSpaces`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type for the request
        },
        body: JSON.stringify({ appUserId }), // Convert the appUserId to JSON and include it in the request body
      });
  
      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        // Parse the response body as JSON
        const result = await response.json();
  
        // Update the state with the totalSpaces value from the response
        setTotalSpaces(result.totalSpaces);
      } else {
        // Log an error message if the request was not successful
        console.error('Failed to fetch total spaces:', response.statusText);
        
        // Set totalSpaces to null in case of an error
        setTotalSpaces(null);
      }
    } catch (error) {
      // Handle any errors that occur during the fetch
      console.error('Error fetching total spaces:', error);
      
      // Set totalSpaces to null in case of an error
      setTotalSpaces(null);
    }
  };
  

  const handleCardClick = async (space_id) => {
    try {
      // Make a GET request to fetch space details using the provided space_id
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/spaces/details/${space_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', // Set the content type for the request
        },
      });
  
      // Check if the request was successful (status code 2xx)
      if (!response.ok) {
        throw new Error(`Failed to fetch space details. Status: ${response.status}`);
      }
  
      // Parse the response body as JSON
      const spaceDetails = await response.json();
  
      // Update the state with the fetched space details
      setSelectedSpace(spaceDetails);
    } catch (error) {
      // Handle any errors that occur during the fetch
      console.error('Error fetching space details:', error.message);
      // You might want to show an error message to the user or log it in your application
    }
  };
  
  

  const handleBackToList = () => {
    setSelectedSpace(null);
  };



  return (
    <>
      <Container>
        <Row>
          <Col md={4} className='mb-3'>
            <Card className='bg-success text-white'>
              <Card.Body>
                <Card.Title>Total Spaces</Card.Title>
                <Card.Text className='text-white'>{totalSpaces}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mb-3 '>
            <Card className='bg-success text-white'>
              <Card.Body>
                <Card.Title>Total Inboxes</Card.Title>
                <Card.Text className='text-white'>{totalInboxes}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mb-3'>
            <Card className='bg-success text-white'>
              <Card.Body>
                <Card.Title>Current Tokens</Card.Title>
                <Card.Text className='text-white'>{currentTokens}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container>
        {selectedSpace ? (
          // Show detailed form when a space is selected
          <SpaceDetailForm space={selectedSpace} onBackToList={handleBackToList} />
        ) : (
          // Show list of cards
          <CardList onCardClick={handleCardClick} />
        )}
      </Container>

    </>
  );
};


const CardList = ({ onCardClick }) => {

  const [spaces, setSpaces] = useState([]);
  const { isSignedIn, user } = useUser();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const currentPage = useRef()
  const [loading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(1)
  const [limit, setlimit] = useState(4)

  const fetchSpaces = async () => {
    let appUserId = user.id;
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/getAllSpaces`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appUserId, page : currentPage.current, limit }),
      });

      if (response.ok) {
        const result = await response.json();

        setpageCount(result.pageCount)
 
        setSpaces(result.spaces);

      } else {
        console.error('Failed to fetch spaces:', response.statusText);
        setSpaces([]);
      }
    } catch (error) {
      console.error('Error fetching spaces:', error);
      setSpaces([]);
    }
  };


    // Invoke when the user clicks to request another page.
    const handlePageClick = async (e) => {
      try {
        // Update the current page based on the selected page from the pagination component
        currentPage.current = e.selected + 1;

        fetchSpaces()

        // Scroll to the top of the window
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Use 'auto' or 'smooth' for scrolling behavior
        });
      } catch (error) {
        // If an error occurs during the process, set the error state
        console.log("error");
      } finally {
        // Set loading state to false regardless of success or failure
        setLoading(false);
      }
    };

  const handleDeleteClick = (space) => {
    setSelectedSpace(space);
    setShowDeleteModal(true);
  };



  const deleteSpace = async (space_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/deleteSpace/${space_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
      });

      if (response.ok) {
        // Space deleted successfully

        toast.success("Space deleted successfully", { position: "top-center" })
        // You can perform any additional actions after deletion
      } else if (response.status === 404) {
        // Space not found
        toast.error(`Space not found`, { position: "top-center" })
      } else {
        // Handle other errors

        toast.error(`Failed to delete space  ${response.statusText}`, { position: "top-center" })
      }
    } catch (error) {
      console.error('Error deleting space:', error);
    }
  };

  const handleDeleteConfirm = () => {
    const userInput = document.getElementById('deleteConfirmation').value;

    // Check if the user input matches the expected confirmation text
    if (userInput.trim().toUpperCase() === 'DELETE') {
      // Perform the delete operation here
      // After deletion, close the modal and update the spaces
      deleteSpace(selectedSpace.space_id)
      setShowDeleteModal(false);
      fetchSpaces();
    } else {
      // Show a Toastify message if the input does not match
      toast.error('Input does not match. Please enter "DELETE" to confirm.', { position: "top-center" });
    }
  };


  useEffect(() => {
    currentPage.current = 1;
    fetchSpaces();
  }, [user.id]);

  return (
    <Container>
      <div className='inner-show-list-box'>
        {spaces.length <= 0 ? (
          <LoadingComponent />
        ) : (
          <div className='Showing-list'>
          {/* // Table once spaces are loaded */}
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Space Name</th>
                <th>Space Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {spaces.map((space, index) => (
                <tr key={index} className="clickable-card">
                  <td>{index + 1}</td>
                  <td>
                    <img src={space.spaceImages[0]} alt={`Space ${space.id}`} className="card-image-dh" />
                  </td>
                  <td>{space.spaceNameTitle}</td>
                  <td>{space.spaceType}</td>
                  <td className='btn-flex'>
                    {/* Edit button */}
                    <button className='btn btn-primary text-white' onClick={() => onCardClick(space.space_id)}>Edit</button>
                    {/* Delete button */}
                    <button className='btn btn-danger bg-danger' onClick={() => handleDeleteClick(space)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          </div>
        )}
          <hr/>
          <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
      </div>

      {/* Delete Modal */}
     
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title><TiWarningOutline className='danger-icon text-danger' />Delete Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-danger">
            Warning: Deleting this space will permanently remove all details associated with it.
            This action cannot be undone. <br></br>Please enter "DELETE" below to confirm.
          </p>
          <Form.Group controlId="deleteConfirmation">
            <Form.Label>Enter <span className='text-danger'>"DELETE"</span> to confirm:</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" className='bg-danger' onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};


const SpaceDetailForm = ({ space, onUpdateSpace, onBackToList }) => {
  const [activeTab, setActiveTab] = useState('Space Location');
  const [formData, setFormData] = useState({ ...space });
  const [spaceTab, setspaceTab] = useState(["Space General Info", "Space Location", "Space Availible", "space Images",  "space policies", "Space Activity"])

  const handleTabSelect = (tab) => {
    // Implement any logic needed when switching tabs
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ImageUpload = ({ spaceId, onImageUpload }) => {
    const dropzoneStyles = {
      border: '2px dashed #cccccc',
      borderRadius: '4px',
      padding: '20px',
      textAlign: 'center',
      height: "15em",
      marginBottom: "4em",
      cursor: 'pointer',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px !important"
    };

    const onDrop = useCallback(async (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (!file) {
        return;
      }

      try {
        const dataUrl = await readFileAsDataUrl(file);

        // Create a FormData object to send the base64-encoded image data
        const formData = {
          'space_id': spaceId,
          'image': dataUrl

        }

        // Make the POST request to the server using fetch
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/spaces/images/add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        // Check if the response is successful (status in the range 200-299)
        if (!response.ok) {
          // If the response is not ok, throw an error with the status and status text
          const errorData = await response.json();
          throw new Error(`${response.status} - ${response.statusText}\n${JSON.stringify(errorData)}`);
        }

        // Handle the response as needed
        const responseData = await response.json();
        if (onImageUpload) {
          onImageUpload(responseData.updatedImages); // Assuming the server sends back the updated images array 
        }

        toast.success('Image uploaded successfully', { position: 'top-center' });
      } catch (error) {
        console.error('Error uploading image:', error);
        toast.error(`Error uploading image: ${error.message}`, { position: 'top-center' });
      }
    }, [spaceId]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*', multiple: false });

    const readFileAsDataUrl = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    return (
      <div>
        <div {...getRootProps()} style={dropzoneStyles}>
          <input {...getInputProps()} />
          <p> <IoCloudUpload className='upload-icon' />Drag & drop an image here, or click to select one</p>
        </div>
        
      </div>
    );
  };





  const ImageTable = () => {

    const [images, setimages] = useState([])

    useEffect(() => {

      setimages([...space.spaceImages])

    }, [])


    const handleDeleteImage = async (imageIndex) => {

      try {
        // Assuming spaceId is available in your component's state or props
        const space_id = space.space_id;

        // Make the DELETE request with spaceId and index in the request body
        const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/spaces/images/delete`, {
          data: { space_id, index: imageIndex },
        });

        const { message, updateImages } = response.data;

        setimages([...updateImages])

        // Show success notification
        toast.success(message, { position: 'top-center' });

      } catch (error) {

        // Show error notification
        toast.error('Error deleting image', { position: 'top-center' });

        console.error('Error:', error);
      }
    };

    return (
      <div>
        <Table striped hover className='table-border'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {images.map((image, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <img src={image} alt={`Image ${index}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                </td>
                <td>
                  <Button variant="danger" className='bg-danger' onClick={() => handleDeleteImage(index)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <ImageUpload spaceId={space.space_id} onImageUpload={(updatedImages) => { setimages([...updatedImages]) }} />

      </div>
    );
  };



  const SpaceActivityTable = ({ spaceActivity , spaceId }) => {
    const [updatedSpaceActivity, setUpdatedSpaceActivity] = useState(spaceActivity);
  
    const handleInputChange = (spaceType, field, value) => {
      setUpdatedSpaceActivity((prevData) => ({
        ...prevData,
        [spaceType]: {
          ...prevData[spaceType],
          [field]: value,
        },
      }));

    };


    const handleUpdate = async () => {
      try {
       
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/space/updateSpaceActivity/${spaceId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedSpaceActivity),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update spaceActivity');
        }
  
        const updatedSpaceActivityResult = await response.json();
        toast.success('SpaceActivity updated successfully!', {position:"top-center"});
        setUpdatedSpaceActivity(updatedSpaceActivityResult.updatedSpace);
      } catch (error) {
        console.error('Error updating spaceActivity:', error);
        toast.error('Failed to update spaceActivity', {position:"top-center"});
      }
    };
  
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Space Type</th>
            <th>Space Price (ksh)</th>
            <th>Min Hours</th>
            <th>Discount (%)</th>
            <th>Max People</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(updatedSpaceActivity).map(([spaceType, spaceDetails], index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{spaceType}</td>
              <td>
                <Form.Control
                  type="text"
                  value={updatedSpaceActivity[spaceType]?.pricing || spaceDetails.pricing}
                  onChange={(e) => handleInputChange(spaceType, 'pricing', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={updatedSpaceActivity[spaceType]?.numPeople || spaceDetails.numPeople}
                  onChange={(e) => handleInputChange(spaceType, 'numPeople', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={updatedSpaceActivity[spaceType]?.discount || spaceDetails.discount}
                  onChange={(e) => handleInputChange(spaceType, 'discount', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={updatedSpaceActivity[spaceType]?.NumPeople || spaceDetails.NumPeople}
                  onChange={(e) => handleInputChange(spaceType, 'NumPeople', e.target.value)}
                />
              </td>
              <td>
                <Button variant="info" className='text-white' onClick={handleUpdate}>Update</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };
  


  const handleFormSubmit = async (spaceAvailable) => {

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/spaces/${space.space_id}/update-available`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ spaceAvailable }),
      });

      if (!response.ok) {
        // Handle non-successful response
        const errorData = await response.json();
        throw new Error(`${response.status} - ${response.statusText}\n${JSON.stringify(errorData)}`);
      }

      const responseData = await response.json();

      // Display success notification
      toast.success('Space Available updated successfully', { position: 'top-center' });

      // Handle the updated space data as needed
      console.log('Updated Space:', responseData.updatedSpace);
    } catch (error) {
      // Display error notification
      toast.error(`Error updating Space Available: ${error.message}`, { position: 'top-center' });

      // Handle the error as needed
      console.error('Error:', error);
    }
  };


  const initialSpaceData = {
    space_username: 'Initial Username',
    space_userimage: 'Initial Image URL',
    spaceType: 'Initial Type',
    spaceArrivalInstructions: 'Initial Instructions',
  };

  // This is feed to space General Information
  const [updatedSpaceData, setUpdatedSpaceData] = useState(initialSpaceData);
  const handleUpdateSpace = (updatedData) => {
    // Handle the logic to update the data in your application state or API
    console.log('Updated Data:', updatedData);
    // For now, let's update the state in this example
    setUpdatedSpaceData(updatedData);
  };



  return (
    <div className="space-detail-form">
      <ToastContainer />
      <div className="back-button" onClick={onBackToList}>
        Back to List
      </div>
      <div className="scrollable-tabs">
        <Tabs
          activeKey={formData.activeTab}
          onSelect={handleTabSelect}
          className="mb-3"
          variant="tabs"
          defaultActiveKey="Space General Info"
          transition={false}
        >

          {(spaceTab).map((key, index) => (
            <Tab key={index} eventKey={key} title={key} activeKey={spaceTab[0]} >
              {key === 'Space General Info' && (
                <>
                  <SpaceForm spaceData={space} onUpdateSpace={handleUpdateSpace} />
                </>
              )}

              {key === 'Space Availible' && (
                <>
                  <SpaceAvailabilityForm
                    spaceAvailabilityData={space.spaceAvailable}
                    onSubmit={handleFormSubmit}
                  />
                </>
              )}
              {key === 'spaceActivity' && (
                <>
                  <Form.Group controlId={`form${key}.Meeting.pricing`}>
                    <Form.Label>Meeting Pricing</Form.Label>
                    <Form.Control
                      type="text"
                      name={`${key}.Meeting.pricing`}
                      value={formData[key].Meeting.pricing}
                      onChange={handleFormChange}
                    />
                  </Form.Group>
                </>
              )}

              {key === 'space Images' && (
                <ImageTable />
              )}
              {/* Render content based on the active tab */}
              {key === 'Space Location' && (
                <MapSelector spaceData={space} />
              )}
              

              {key === "Space Activity" && (
                <SpaceActivityTable spaceActivity={space.spaceActivity} spaceId={space.space_id}/>
              )}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};



export default SpaceComponent;
