// CardInfo.js

import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { BsFillGearFill } from 'react-icons/bs'; // Import React icons
import { HiViewfinderCircle } from "react-icons/hi2";
import "./inforcard.css";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { BsFillClipboardDataFill } from "react-icons/bs";

const CardInfo = () => {
  return (
    <Container className='mb-5'>
      <Row className="justify-content-center" xs={1} sm={1} md={2} lg={3} xl={3}>
        <Col >
          <Card className="mb-4 card-info-1 card-hover card-hover-effect">
            <Card.Body>
              <Card.Title className='mb-3'>
                <HiViewfinderCircle className="react-icon" />
               <h5 className='header-inline-block'> Find the perfect space</h5>
              </Card.Title>
              <Card.Text>
                Browse the marketplace and tailor your search to your activity needs.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col >
          <Card className="mb-4 card-info-2 card-hover card-hover-effect">
            <Card.Body>
              <Card.Title className='mb-3'>
                <RiVerifiedBadgeFill className="react-icon" />
                <h5 className='header-inline-block'> Book it with ease </h5>
              </Card.Title>
              <Card.Text>
                Once you find a perfect match, book the space and pay online through our easy-to-use payment system.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col >
          <Card className="mb-4 card-info-3 card-hover card-hover-effect">
            <Card.Body>
              <Card.Title  className='mb-3'>
                <BsFillClipboardDataFill className="react-icon" />
                <h5 className='header-inline-block'>  Meet, make or create </h5>
               
              </Card.Title>
              <Card.Text>
                Create a memorable experience in a memorable space, and enjoy!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CardInfo;
