import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

const ReviewForm = ({ onSubmit }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleRatingChange = (value) => {
    setRating(value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the form is incomplete
    if (rating === 0 && comment.trim() === '') {
      toast.error('Please provide a rating and a comment before submitting.');
    } else if (rating === 0) {
      toast.error('Please provide a rating before submitting.');
    } else if (comment.trim() === '') {
      toast.error('Please provide a comment before submitting.');
    } else {
      // Call the onSubmit prop with the review data
      onSubmit({ rating, comment });

    
      // Reset form values
      setRating(0);
      setComment('');
    }
  };

  return (
    <Card className='review-form-section'>
      <ToastContainer />
      <Card.Body>
        <div className='Event-price'>
          <span className='price-head'> Leave a Review </span>
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="rating">
            <Form.Label>Rating</Form.Label>
            <div className="d-flex align-items-center mb-3">
              {[...Array(5)].map((_, index) => (
                <FaStar
                  key={index}
                  className={`star-icon m-1 ${index + 1 <= rating ? 'text-warning' : ''}`}
                  onClick={() => handleRatingChange(index + 1)}
                />
              ))}
            </div>
          </Form.Group>

          <Form.Group controlId="comment">
            <Form.Label>Review Comment</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={comment}
              onChange={handleCommentChange}
              placeholder="Write your review here..."
              className='w-100'
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-4 w-100 submit-review">
            Submit Review
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ReviewForm;
