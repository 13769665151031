// Import necessary React and Bootstrap components
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './CityCardContainer.css'; // Import the custom CSS file

import nai from "../images/nai.jpg";
import nai2 from "../images/nai2.jpg";
import nai3 from "../images/nai3.jpg";
import nai4 from "../images/nai4.jpg";

// Data containing city names and corresponding images
const cityData = [
  { name: 'WESTLANDS', imageUrl: nai },
  { name: 'KAREN', imageUrl: nai2 },
  { name: "LANG'ATA", imageUrl: nai3 },
  { name: 'KILIMANI', imageUrl: nai4 },
  { name: 'LAVINGTON', imageUrl: nai2 },
  { name: 'EASTLANDS', imageUrl: nai },
];

// Your main component
const CityCardContainer = () => {
  return (
    <Container>
        <h1>Popular locations in Nairobi</h1>
        <p className='mb-5'>We've unlocked spaces all over Nairobi</p>
      <Row className="justify-content-center city-top-con" xs={1} sm={1} md={2} lg={3} xl={3}>
        {cityData.map((city, index) => (
          <Col key={index} className='col-city' >
            <Card className={`text-center city-card`}>
              <Card.Img variant="top" src={city.imageUrl} alt={city.name} className="card-img-top" />
              <h2 className='text-centerof-image'>{city.name}</h2>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CityCardContainer;
