import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "../images/home-address.png"
import { MdPrivacyTip } from "react-icons/md";
import axios from 'axios'


import './utili.css';

const MapSelect = () => {
  const [selectedLocation, setSelectedLocation] = useState({
    lat: -1.286389,
    lng: 36.817223,
  });

  const [spaceDataFetch, setSpaceDatafetch] = useState(null);
  const [marker, setMarker] = useState(null);
  const [location, setLocation] = useState('');
  const [zoomIncrement, setZoomIncrement] = useState(2);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '3em',
  };

  const center = {
    lat: selectedLocation?.lat || 0,
    lng: selectedLocation?.lng || 0,
  };

  useEffect(() => {
    // Perform any cleanup logic when the component is unmounted
    return () => {
      console.log('MapContainer unmounted');
    };
  }, []); // 



  const handlePlaceSelect = (place) => {
    // Check if place is truthy and has a valid geometry property
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setSelectedLocation({ lat, lng });
      
      // Increase the zoom increment for the next click
      setZoomIncrement((prevIncrement) => prevIncrement + 2);


    } else {
      // Notify user of an error
      toast.error('Error saving location', {
        position: 'top-center',
      });

      console.error('Invalid place object:', place);
    }

  };

  // Function invoked when the user clicks on the map
  const handleMapClick = (event) => {
    // Extract latitude and longitude from the click event  const [location, setLocation] = useState('');

    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Set the selected location using the extracted coordinates
    setSelectedLocation({ lat, lng });

    // Set the marker for the selected location
    setMarker({ lat, lng });

    localStorage.setItem("location", JSON.stringify({ lat, lng }))

    // Set the location state for further use or saving
    setLocation({ lat: lat, lng: lng });
  };


  return (
    <div style={{position:"relative"}}>
      {/* Display selected location details if a marker is present */}
      {marker && (
        <>
          <div>
            <h3>Selected Location:</h3>
            <p>Latitude: {marker.lat}</p>
            <p>Longitude: {marker.lng}</p>
          </div>

        </>
      )}

      {/* Load the Google Maps script with Autocomplete and Map components */}
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API}
        libraries={['places']}
       
      >
        <div style={{ top: '10px', left: '10px', background: 'white', padding: '10px', zIndex: 1000 }}>
            <p><MdPrivacyTip className='text-warning' style={{ fontSize: "30px" }} />Click on the map to select the location for your space.</p>
          </div>
        <div>
          {/* Autocomplete component for location search */}
          <Autocomplete
              apiKey={process.env.REACT_APP_GOOGLE_API}
              options={{
                types: ['geocode', 'establishment'],
                componentRestrictions: { country: "KE" },
              }}
              className='input-location'
              onPlaceSelected={(place) => handlePlaceSelect(place)}
            />

        </div>

        {/* GoogleMap component for displaying the map */}
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={12}
          onClick={(event) => handleMapClick(event)}
        >
          {/* Display the marker on the map if present */}
          {marker && <MarkerF position={marker} icon={Home} />}
          {/* Instruction message to guide users */}
        </GoogleMap>
      </LoadScript>

      {/* ToastContainer for displaying notifications */}
      <ToastContainer />
    </div>

  );
};

export default MapSelect;
