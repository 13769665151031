import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScriptNext, MarkerF, InfoWindow } from '@react-google-maps/api';
import home from './../images/home-address.png';

const MapContainer = ({ markers }) => {
  const mapStyles = {
    height: '100vh',
    width: '100%',
  };


  const defaultCenter = { lat: -1.286389, lng: 36.817223 }; // Nairobi coordinates
  const defaultZoom = 12;

  const [mapOptions, setMapOptions] = useState({
    center: defaultCenter,
    zoom: defaultZoom,
  });

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [zoomIncrement, setZoomIncrement] = useState(2);

  const handleMarkerClick = (marker) => {
    // Update mapOptions to zoom in when a marker is clicked
    setMapOptions((prevOptions) => ({
      center: { lat: marker.location.lat, lng: marker.location.lng },
      zoom: prevOptions.zoom + zoomIncrement,
    }));

    // Increase the zoom increment for the next click
    setZoomIncrement((prevIncrement) => prevIncrement + 2);
  };

  const handleInfoWindowClose = () => {
    // Close the InfoWindow by setting selectedMarker to null
    setSelectedMarker(null);
  };

  useEffect(() => {
    // Perform any cleanup logic when the component is unmounted
    return () => {
      console.log('MapContainer unmounted');
    };
  }, []); // The empty dependency array ensures this effect only runs on unmount

  if (!markers || markers.length === 0) {
    return <p>The location is note yet set</p>;
  }

  const MemoizedMarker = React.memo(({ position, title, icon, onClick }) => (
    <MarkerF position={position} title={title} icon={icon} onClick={onClick}>
      {/* Conditionally render the InfoWindow if this marker is selected */}
      {selectedMarker === position && (
        <InfoWindow onCloseClick={handleInfoWindowClose}>
          <div>
            <h3>{title}</h3>
          </div>
        </InfoWindow>
      )}
    </MarkerF>
  ));

  return (
    <LoadScriptNext
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API}
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={mapOptions.zoom}
        center={mapOptions.center}
      >
      {
         markers.map((marker, index) => (
            <MemoizedMarker
              key={index}
              position={{ lat: marker.location?.lat, lng: marker.location?.lng }}
              title={marker.title}
              label={marker.title}
              icon={home}
              onClick={() => handleMarkerClick(marker)}
            />
          ))
        }
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default MapContainer;
