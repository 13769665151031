import React from 'react';
import "./loading.css";

const LoadingComponent = () => {
  return (
    <>
      <div class="custom-loader-container">
        <div className="lds-ripple"><div></div><div></div></div>
      </div>
    </>
  );
};

export default LoadingComponent;