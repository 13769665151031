import React, { useState, useEffect } from 'react';
import { Container, Card, Form } from 'react-bootstrap';
import './HeroSection.css'; // Import the custom CSS file
import video from "../images/intro.mp4";

const HeroSection = () => {

  const videoStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
  };

  return (
    <div className='main-dev'>
      <div className="hero-section">
        <video autoPlay loop muted style={videoStyle}>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <div className='card-hero-main-container'>
            <Card className="search-card">
              <Card.Body>
                <h6 md={3}>Discover & Book Unique Spaces for Your Upcoming Activity</h6>
                <div className="selection-block">
                  <Form.Select className="search-selection">
                    <option value="" disabled selected>
                      What are you planning?
                    </option>
                    <option value="meeting">Meeting</option>
                    <option value="workshop">Workshop</option>
                    <option value="event">Event</option>
                  </Form.Select>
                </div>
                <div className="selection-block">
                  <Form.Select className="search-selection">
                    <option value="" disabled selected>
                      Where are you going?
                    </option>
                    <option value="cityA">City A</option>
                    <option value="cityB">City B</option>
                    <option value="cityC">City C</option>
                  </Form.Select>
                </div>
                <div className="guests-input">
                  <input type="number" placeholder="Enter Number of Guests" className="guests-input-in" />
                </div>
                <div className="search-btn">
                  <button className="btn btn-primary btn-with-search">Search</button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
