// src/pages/AllEventsPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row} from 'react-bootstrap';
import EventSpaceCard from '../components/EventSpaceCard';
import LoadingComponent from '../utilities/utilities';
import './AllEventsPage.css'; // Import the custom CSS file

function Recom(props) {
    const [filterEvent, setFilterEvent] = useState(false);
    const [eventSpacesData, setEventSpacesData] = useState([]);
  
    const [limit, setlimit] = useState(6);
    const [pageCount, setpageCount] = useState(1);
    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getIntialUser();
    }, []);

    const getIntialUser = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/api/spaces/list/rand?page=${currentPage.current}&limit=${limit}`,
                {
                    method: 'GET', 
                    headers: {
                        'Content-Type': 'application/json',
                      },
                }
            );
    
            if (!response.ok) {
                throw new Error('Failed to fetch spaces');
            }
    
            const result = await response.json();
            setpageCount(result.pageCount);
            setEventSpacesData(result.spaces);
        } catch (error) {
            console.log(error.message);
        }
    };
    

    return (
        <Container>
            {eventSpacesData?.length > 0 ? (
                <Row xs={1} sm={1} md={2} lg={3} className="g-4">
                    {eventSpacesData.map((eventSpace, index) => (
                        <EventSpaceCard key={index} data={eventSpace} />
                    ))}
                </Row>
            ) : (
                <LoadingComponent />
            )}

        </Container>

    );
}


export default Recom;
