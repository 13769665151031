// HostSignupText.js

import React from 'react';
import { Container } from 'react-bootstrap';

const HostSignupText = () => {
  return (
    <Container className="text-center mt-5 mb-5">
      <h1>Become a SpaceYettu host</h1>
      <p>List For Free, Set Your Price and Start Earning</p>
      <a href='/become-host' className='host-btn'>List Your Space</a>
    </Container>
  );
};

export default HostSignupText;
