import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "../images/home-address.png"
import { MdPrivacyTip } from "react-icons/md";
import axios from 'axios'

import './utili.css';


const MapSelector = ({ spaceData }) => {
  const [selectedLocation, setSelectedLocation] = useState({
    lat: -1.286389,
    lng: 36.817223,
  });

  const [spaceDataFetch, setSpaceDatafetch] = useState(null);
  const [marker, setMarker] = useState(null);
  const [spaceId, setSpaceId] = useState('');
  const [spaceTitle, setSpaceTitle] = useState('');
  const [spaceType, setSpaceType] = useState('');
  const [location, setLocation] = useState('');
  const [zoomIncrement, setZoomIncrement] = useState(2);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '3em',
  };

  const center = {
    lat: selectedLocation?.lat || 0,
    lng: selectedLocation?.lng || 0,
  };

  useEffect(() => {
    setSpaceId(spaceData.space_id);
    setSpaceTitle(spaceData.spaceNameTitle);
    setSpaceType(spaceData.spaceType);
    fetchSpaceData();

     // Perform any cleanup logic when the component is unmounted
     return () => {
      console.log('MapSelector unmounted');
    };
  }, [spaceData]);

  const fetchSpaceData = async () => {
    try {
      let spaceId = spaceData.space_id;
      // Fetch data from the API
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/spaceLocation/SpaceLocation/${spaceId}`,
        {
          method: 'GET', // specify the HTTP method
          headers: {
            'Content-Type': 'application/json', // set the Content-Type header
            // Add any other headers if needed
          },
        }
      );
  
      // Check if the response is successful
      if (!response.ok) {
        throw new Error('Failed to fetch space data');
      }
  
      // Parse the JSON data
      const data = await response.json();
      // Update the state with the fetched data
      setSpaceDatafetch(data);
      setMarker(data.location);
    } catch (error) {
      // Handle errors
      console.error(error.message);
    }
  };
  

  const handlePlaceSelect = (place) => {
    // Check if place is truthy and has a valid geometry property
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setSelectedLocation({ lat, lng });

      // Increase the zoom increment for the next click
      setZoomIncrement((prevIncrement) => prevIncrement + 2);




    } else {
      // Notify user of an error
      toast.error('Error saving location', {
        position: 'top-center',
      });

      console.error('Invalid place object:', place);
    }
  };

  // Function invoked when the user clicks on the map
  const handleMapClick = (event) => {
    // Extract latitude and longitude from the click event
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Set the selected location using the extracted coordinates
    setSelectedLocation({ lat, lng });

    // Set the marker for the selected location
    setMarker({ lat, lng });

    // Set the location state for further use or saving
    setLocation({ lat: lat, lng: lng });

    // Call the handleSave function to save the location (adjust this logic as needed)
    handleSave();
  };

  const handleSave = async () => {
    try {
      // Validate form fields

      // Check if any required field is missing
      if (!spaceId || !spaceTitle || !spaceType ) {
        const missingFields = [];

        // Check and add missing fields to the list
        if (!spaceId) missingFields.push('Space ID');
        if (!spaceTitle) missingFields.push('Space Title');
        if (!spaceType) missingFields.push('Space Type');

        // Construct an error message with the missing fields
        const errorMessage = `Please fill in the following fields: ${missingFields.join(', ')}.`;

        // Display an error toast with the error message
        toast.error(errorMessage, { position: "top-center" });
        return;
      }

      // Send a POST request to create or update the space location
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/spaceLocation/SpaceLocation`, {
        space_id: spaceId,
        space_title: spaceTitle,
        space_type: spaceType,
        location: location,
      });

      // Handle success
      toast.success('Space location saved successfully.', { position: "top-center" });
    } catch (error) {
      // Handle errors
      console.error(error.message);

      // Display an error toast for any other errors
      toast.error('Error saving space location. Please try again.', { position: "top-center" });
    }
  };

  return (
    <>
      {/* Display selected location details if a marker is present */}
      {marker && (
        <>
          <div>
            <h3>Selected Location:</h3>
            <p>Latitude: {marker.lat}</p>
            <p>Longitude: {marker.lng}</p>
          </div>

        </>
      )}

      {/* Load the Google Maps script with Autocomplete and Map components */}
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API}
        libraries={['places']}
      >
        <div>
          {/* Autocomplete component for location search */}
          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API}
            options={{
              types: ['geocode', 'establishment'],
              componentRestrictions: { country: "KE" },
            }}
            onPlaceSelected={(place) => handlePlaceSelect(place)}
            className='input-location'
          />
        </div>

        {/* GoogleMap component for displaying the map */}
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={12}
          onClick={(event) => handleMapClick(event)}
        >
          {/* Display the marker on the map if present */}
          {marker && <MarkerF position={marker} icon={Home} />}
          {/* Instruction message to guide users */}
          <div style={{ position: 'absolute', top: '10px', left: '10px', background: 'white', padding: '10px', zIndex: 1000 }}>
            <p><MdPrivacyTip className='text-warning' style={{ fontSize: "30px" }} />Click on the map to select the location for your space.</p>
          </div>
        </GoogleMap>
      </LoadScript>

      {/* ToastContainer for displaying notifications */}
      <ToastContainer />
    </>

  );
};

export default MapSelector;
