// src/pages/AllEventsPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Button, Modal, Row, Col } from 'react-bootstrap';
import './AllEventsPage.css'; // Import the custom CSS file
import axios from 'axios'; // Import axios
import ReactPaginate from 'react-paginate';


import EventSpaceCard from '../components/EventSpaceCard';
import LoadingComponent from '../utilities/utilities';
const LazyMapContainer = React.lazy(() => import('../utilities/MapContainer'));

const AllEventsPage = (props) => {
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [size, setSize] = useState(500);
  const [price, setPrice] = useState(500);
  const [filterEvent, setFilterEvent] = useState(false);
  const [eventSpacesData, setEventSpacesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Data, setData] = useState(null)
  const [limit, setlimit] = useState(6)
  const [pageCount, setpageCount] = useState(1)
  const currentPage = useRef()
  const [spaceIds, setspaceIds] = useState([])
  const [markers, setmarkers] = useState([])


  useEffect(() => {
    currentPage.current = 1;
    getIntialUser()
   

  }, [filterEvent]);




  const handleSizeChange = (e) => {
    setSize(parseInt(e.target.value, 10));
  };

  const handlePriceChange = (e) => {
    setPrice(parseInt(e.target.value, 10));
  };

  const handleShowFilters = () => {
    setShowFiltersModal(true);
  };

  const handleCloseFilters = () => {
    setShowFiltersModal(false);
  };

  const handleApplyFilters = () => {
    // Handle applying filters (you can add the logic here)
    handleCloseFilters();
  };


 // Invoke when the user clicks to request another page.
const handlePageClick = async (e) => {
  try {
    // Update the current page based on the selected page from the pagination component
    currentPage.current = e.selected + 1;

    setEventSpacesData([])

    // Fetch data for the updated page
    getIntialUser();

    

    // Scroll to the top of the container
    const container = document.querySelector('.all-events-inner-con');
    if (container) {
      container.scrollTop = 0;
    }

    // Scroll to the top of the window
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Use 'auto' or 'smooth' for scrolling behavior
    });
  } catch (error) {
    // If an error occurs during the process, set the error state
    setError(error.message);
  } finally {
    // Set loading state to false regardless of success or failure
    setLoading(false);
  }
};


const fetchSpaceData = async (spaceArray) => {

  try {

      // Check if the input is a valid array and not empty
    if (!Array.isArray(spaceArray) || spaceArray.length === 0) {
      console.error('Invalid input array');
      return [];
    }

    // Map each space object to its space_id property
    const spaceIds = spaceArray.map(space => space.space_id);

    // Ensure spaceIds is a valid array
    if (!Array.isArray(spaceIds) || spaceIds.length === 0) {
      throw new Error('Please provide a valid array of spaceIds');
    }

    // Perform the fetch request
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/spaceLocation/SpaceLocation/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ spaceIds }),
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error('Failed to fetch space data');
    }

    // Parse the JSON data
    const data = await response.json();
   

    // Update the state with the fetched data
    setmarkers(data)

  } catch (error) {

    // Handle errors
    console.error(error);
  }
};



const getIntialUser = async () => {
  try {
    // Fetch data from the API with the current page and limit parameters
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/spaces/list?page=${currentPage.current}&limit=${limit}`,
      {
        method: 'GET', // specify the HTTP method
        headers: {
          'Content-Type': 'application/json', // set the Content-Type header
          // Add any other headers if needed
        },
      }
    );

    // Check if the HTTP response is successful (status code 2xx)
    if (!response.ok) {
      // If not successful, throw an error
      throw new Error('Failed to fetch spaces');
    }

    // Parse the JSON response
    const result = await response.json();

    // Update the state with the received data
    fetchSpaceData(result.spaces);
    setpageCount(result.pageCount);
    setEventSpacesData(result.spaces);

    // Log the result to the console (for debugging)
  } catch (error) {
    // Handle any errors that occurred during the fetch operation
    console.log(error.message);
  }
};



  return (
    <div >
      {(props.filterEvent === true) && (<>
      </>)}
      <Modal show={showFiltersModal} onHide={handleCloseFilters}>
        <Modal.Header closeButton>
          <Modal.Title>More Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="amenities">
            <Form.Label>Amenities</Form.Label>
            <div className="amenities-checkboxes">
              <Form.Check type="checkbox" label="TV" />
              <Form.Check type="checkbox" label="Air Conditioning" />
              <Form.Check type="checkbox" label="Wireless Internet" />
              <Form.Check type="checkbox" label="Tables" />
              <Form.Check type="checkbox" label="Chairs" />
              <Form.Check type="checkbox" label="Wardrobe Rack" />
              <Form.Check type="checkbox" label="Lighting Equipment" />
              <Form.Check type="checkbox" label="White Backdrop" />
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFilters}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleApplyFilters}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="all-events-container">
        <div className='all-events-inner-con'>
          {eventSpacesData.length > 0 ? (
            <Row xs={1} sm={1} md={2} lg={2} className="g-4">
              {eventSpacesData.map((eventSpace, index) => (
                <EventSpaceCard key={index} data={eventSpace} />
              ))}
            </Row>
          ) : (
            <LoadingComponent />
          )}
          <hr />
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
        <div className='map-container'>
          {eventSpacesData.length > 0 ? (
            <React.Suspense fallback={<div>Loading...</div>}>
              <LazyMapContainer markers={markers} />
            </React.Suspense>
          ) : (
            <LoadingComponent />
          )}
        </div>
      </div>
      {(props.filterEvent === true) && (<>
        <hr />
        <Container className='mt- mb-5'>
        </Container>
      </>)}
    </div>
  );
};


export default AllEventsPage;
