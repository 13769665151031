import React, { useState } from 'react';
import { Table, Form, Button } from 'react-bootstrap';

const SpaceAvailabilityForm = ({ spaceAvailabilityData, onSubmit }) => {
  const [formData, setFormData] = useState(spaceAvailabilityData);


  const handleChange = (day, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [day]: {
        ...prevData[day],
        [field]: value,
      },
    }));

    console.log(formData);
  };

  const handleHoursChange = (day, when, time) => {

    setFormData((prevData) => ({
      ...prevData,
      [day]: {
        ...prevData[day],
        hours:{
          ...prevData[day]?.hours,
          [when]: time
        } 
      },
    }));

    console.log(formData);

  }

  const handleSubmit = () => {
    // Perform any validation if needed before submitting
    
    onSubmit(formData);
  };

  return (
    <Form className='mb-5'>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Day</th>
            <th>Is Open</th>
            <th>Hours Type</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(formData).map((day) => (
            <tr key={day}>
              <td>{day}</td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={formData[day].isOpen}
                  onChange={(e) => handleChange(day, 'isOpen', e.target.checked)}
                />
              </td>
              <td>
                <Form.Control
                  as="select"
                  value={formData[day].hoursType}
                  onChange={(e) => handleChange(day, 'hoursType', e.target.value)}
                >
                  <option value="alldays">All Days</option>
                  <option value="sethours">Set Hours</option>
                </Form.Control>
              </td>
              <td>
                <Form.Control
                  type="time"
                  defaultValue="06:00"
                  disabled={formData[day].hoursType !== 'sethours'}
                  value={formData[day].hours?.startDate}
                  onChange={(e) => handleHoursChange(day, 'startDate', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="time"
                  defaultValue="18:00"
                  disabled={formData[day].hoursType !== 'sethours'}
                  value={formData[day].hours?.endDate}
                  onChange={(e) => handleHoursChange(day, 'endDate', e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
  );
};

export default SpaceAvailabilityForm;
