// src/components/NavbarComponent.js
import React, { useEffect, useState } from 'react';
import { Navbar, Nav} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth, UserButton, SignOutButton ,useUser} from "@clerk/clerk-react";

const NavbarComponent = () => {
  const { isSignedIn, user } = useUser();
  const [hasSpace, setHasSpace] = useState(false);
  const navigate = useNavigate();



  // Fetch method to check if the user has any associated space
  const checkUserSpace = async () => {
    let appUserId = user?.id;
   
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/checkUserSpace`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appUserId }),
      });

      if (response.ok) {
        const result = await response.json();
 
        setHasSpace(result.hasSpace);
      } else {
        console.error('Failed to fetch user space:', response.statusText);
        setHasSpace(false); // Set to false in case of an error
      }
    } catch (error) {
      console.error('Error fetching user space:', error);
      setHasSpace(false); // Set to false in case of an error
    }
  };

  useEffect(() => {
    // Use useEffect to perform navigation only once when the component mounts
    if (!isSignedIn) {
      navigate('/');
    }

    checkUserSpace();
  }, [isSignedIn]);


  return (
    <Navbar bg="light" expand="lg" className='nav-conatiner'>
        <Navbar.Brand as={Link} to="/">SpaceYettu</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto" navbarScroll>
            {/* <Form className="d-flex">
              <Form.Control type="search" placeholder="Search" className="me-2" />
              <Button variant="outline-success">Search</Button>
            </Form> */}
          </Nav>

          <Nav>
            {isSignedIn ? (
              <>
                <Nav.Link as={Link} to="/all-spaces">Spaces</Nav.Link>
                <Nav.Link as={Link} to="/become-host">Become a host</Nav.Link>
                {hasSpace === true &&  <Nav.Link as={Link} to="/hostDashbord">Dashboard</Nav.Link> }
                <UserButton />
                <SignOutButton className="app-signout-button"/>
                
              </>
            ) : (
              <>
                <Nav.Link as={Link} to="/all-spaces">Spaces</Nav.Link>
                <Nav.Link as={Link} to="/become-host">Become a host</Nav.Link>
                <Nav.Link as={Link} to="/signin">Sign in</Nav.Link>
                <Nav.Link as={Link} to="/signup">Sign up</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
