import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container, Table, Button, Modal , Breadcrumb} from 'react-bootstrap';
import { useUser } from '@clerk/clerk-react';
import { FaEye, FaLock } from 'react-icons/fa';
import LoadingComponent from '../../utilities/utilities';
import { IoChevronBackCircleOutline } from "react-icons/io5";



const calculateTimePassed = (postedDate) => {
  const now = new Date();
  const posted = new Date(postedDate);
  const timeDifference = now - posted;

  if (timeDifference < 60000) {
    // Less than a minute
    return `${Math.floor(timeDifference / 1000)} seconds ago`;
  } else if (timeDifference < 3600000) {
    // Less than an hour
    return `${Math.floor(timeDifference / 60000)} minutes ago`;
  } else if (timeDifference < 86400000) {
    // Less than a day
    return `${Math.floor(timeDifference / 3600000)} hours ago`;
  } else {
    // More than a day
    return `${Math.floor(timeDifference / 86400000)} days ago`;
  }
};

const BookingList = ({ bookings }) => {
  let serverUrl = `${process.env.REACT_APP_SERVER_URL}`;
  const { isSignedIn, user } = useUser();

  const [bookingSummary, setBookingSummary] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);

  useEffect(() => {
    // Replace 'your_space_owner_id' with the actual space owner ID
    const space_owner = user.id;

    fetch(`${serverUrl}/booking/bookingsBySpaceOwner/${space_owner}`)
      .then((response) => response.json())
      .then((data) => {
        const summary = Object.entries(data).map(([spaceId, bookingsForSpace]) => ({
          spaceId,
          Space_Name: bookingsForSpace.length > 0 ? bookingsForSpace[0].Space_Name : '',
          totalBookings: bookingsForSpace.length,
          seenBookings: bookingsForSpace.filter((booking) => booking.space_booking_details.booking_reviewed).length,
          lockedBookings: bookingsForSpace.filter((booking) => booking.space_booking_details.booking_locked).length,
          bookings: bookingsForSpace, // Add the bookings array for each space
        }));
        setBookingSummary(summary);
      })
      .catch((error) => console.error('Error fetching bookings:', error));
  }, []);

  const handleBookingClick = (selectedSpaceId) => {
    const selectedSpace = bookingSummary.find((space) => space.spaceId === selectedSpaceId);
    setSelectedBooking(selectedSpace);
  };

  const handleBackButtonClick = () => {
    setSelectedBooking(null);
  };

  const BookingDetailsTable = ({ bookings }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);

    const handleUnlockButtonClick = (booking) => {
      setSelectedBooking(booking);
      setShowModal(true);
    };

    const handleCloseModal = () => {
      setSelectedBooking(null);
      setShowModal(false);
    };


    return (
      <>
       <Breadcrumb>
        <Breadcrumb.Item onClick={handleBackButtonClick}>
          <IoChevronBackCircleOutline className='booking-icon' />
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={handleBackButtonClick}>
           Booking List
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Booking Details</Breadcrumb.Item>
      </Breadcrumb>
       
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>_id</th>
              <th>posted_date</th>
              <th>Space_Name</th>
              <th>Unlock Button</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking._id}>
                <td>{booking._id}</td>
                <td>{calculateTimePassed(booking.posted_date)}</td>
                <td>{booking.Space_Name}</td>
                <td>
                  <Button variant="primary" onClick={() => handleUnlockButtonClick(booking)}>
                    Unlock
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Modal to show full details about the booker */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Booking Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedBooking && (
              <div>
                <p>Name of Booker: {selectedBooking.space_booking_details.name_of_booker}</p>
                <p>Number of Guests: {selectedBooking.space_booking_details.number_of_guests}</p>
                {/* Add more details as needed */}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }


  const BookingSummaryTable = () => (
    <>
      <h5>Booking Summary</h5>
      {!bookingSummary.length > 0 ? (
        <LoadingComponent />
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Space ID</th>
              <th>Space Name</th>
              <th>Total Bookings</th>
              <th>Seen Bookings</th>
              <th>Locked Bookings</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {bookingSummary.map((summary, index) => (
              <tr key={summary.spaceId}>
                <td>{index + 1}</td>
                <td>{summary.spaceId}</td>
                <td>{summary.Space_Name}</td>
                <td>{summary.totalBookings}</td>
                <td>
                  <FaEye className="booking-icon" /> {summary.seenBookings}
                </td>
                <td>
                  <FaLock className="booking-icon" /> {summary.lockedBookings}
                </td>
                <td>
                  <Button variant="primary" onClick={() => handleBookingClick(summary.spaceId)}>
                    View Bookings
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );

  return (
    <div>
      <Container>
        <Row>
          <Col md={4} className='mb-3'>
            <Card className='bg-success text-white'>
              <Card.Body>
                <Card.Title>Total Spaces</Card.Title>
                <Card.Text className='text-white'>{ }</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mb-3 '>
            <Card className='bg-success text-white'>
              <Card.Body>
                <Card.Title>Total Inboxes</Card.Title>
                <Card.Text className='text-white'>{ }</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className='mb-3'>
            <Card className='bg-success text-white'>
              <Card.Body>
                <Card.Title>Current Tokens</Card.Title>
                <Card.Text className='text-white'>{ }</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {selectedBooking ? (
          <BookingDetailsTable bookings={selectedBooking.bookings} />
        ) : (
          <BookingSummaryTable />
        )}
      </Container>
    </div>
  );
};

export default BookingList;
