// src/components/Home.js
import React from 'react';
import HeroSection from './HeroSection';
import SpaceSection from './ActivitySection';
import Recommendation from './Recommendation';
import CityCardContainer from './Popular';
import CardInfo from './CardInfo';
import HostSignupText from './HostSignupText';


const Home = () => {
  return (
    <div>
      <HeroSection/>
      <SpaceSection/>
      <Recommendation/>
      <CityCardContainer/>
      <CardInfo/>
      <HostSignupText/>
    </div>
  );
};

export default Home;
