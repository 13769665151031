import React, { useState } from 'react';
import { Form, Table, Button } from 'react-bootstrap';
import { ToastContainer, toast} from "react-toastify"
import TextareaAutosize from 'react-textarea-autosize';


const SpaceForm = ({ spaceData, onUpdateSpace }) => {
    const [formData, setFormData] = useState({ ...spaceData });
    let serverRoute = `${process.env.REACT_APP_SERVER_URL}`
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };



    // Assuming serverRoute is a variable containing the base URL of your server
    const handleSpaceNameUpdateField = async (fieldName) => {
        try {
        // Send a PUT request to update space username
        const response = await fetch(`${serverRoute}/api/space/updateSpaceUsername/${spaceData._id}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            // Include the newSpaceUsername in the request body
            body: JSON.stringify({
            newSpaceUsername: formData.space_username,
            }),
        });
    
        // Check if the request was unsuccessful (HTTP status code outside the range 200-299)
        if (!response.ok) {
            // Parse the error response and display an error message using toast
            const errorData = await response.json();
            toast.error(errorData.error || 'Failed to update space username', { position: "top-center" });
            return;
        }
    
        // If the request was successful, parse the response and display a success message using toast
        const updatedSpace = await response.json();
        toast.success('Space username updated successfully', { position: "top-center" });
        // Call the onUpdateSpace function with the updated space data
        onUpdateSpace(updatedSpace.updatedSpace);
        } catch (error) {
        // Handle any unexpected errors and display an error message using toast
        console.error(error);
        toast.error('Internal Server Error', { position: "top-center" });
        }
    };
  


    const handleUpdateSpaceType = async () => {
        try {
          // Validate formData.spaceType (you can add more validation rules as needed)
          if (typeof formData.spaceType !== 'string' || formData.spaceType.trim() === '') {
            toast.error('Invalid spaceType');
            return;
          }
    
          const response = await fetch(`${serverRoute}/api/space/updateSpaceType/${spaceData._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              newSpaceType: formData.spaceType,
            }),
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.error || 'Failed to update spaceType', {position: "top-center"});
            return;
          }
    
          const updatedSpace = await response.json();
          toast.success('SpaceType updated successfully', {position: "top-center"});
          onUpdateSpace(updatedSpace.updatedSpace);
        } catch (error) {
          console.error(error);
          toast.error('Internal Server Error');
        }
      };  



      const handleUpdateSpaceArrivalInstructions = async () => {
        try {
          // Validate formData.spaceArrivalInstructions
          if (typeof formData.spaceArrivalInstructions !== 'string') {
            toast.error('Invalid spaceArrivalInstructions');
            return;
          }
    
          const response = await fetch(`${serverRoute}/api/space/updateSpaceArrivalInstructions/${spaceData._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              newSpaceArrivalInstructions: formData.spaceArrivalInstructions,
            }),
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.error || 'Failed to update spaceArrivalInstructions', { position: "top-center" });
            return;
          }
    
          const updatedSpace = await response.json();
          toast.success('SpaceArrivalInstructions updated successfully', { position: "top-center" });
          onUpdateSpace(updatedSpace.updatedSpace);
        } catch (error) {
          console.error(error);
          toast.error('Internal Server Error', { position: "top-center" });
        }
      };



      const handleUpdateSpaceNameTitle = async () => {
        try {
          // Validate formData.spaceNameTitle
          if (typeof formData.spaceNameTitle !== 'string') {
            toast.error('Invalid spaceNameTitle');
            return;
          }
    
          const response = await fetch(`${serverRoute}/api/space/updateSpaceNameTitle/${spaceData._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              newSpaceNameTitle: formData.spaceNameTitle,
            }),
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.error || 'Failed to update spaceNameTitle', { position: "top-center" });
            return;
          }
    
          const updatedSpace = await response.json();
          toast.success('SpaceNameTitle updated successfully', { position: "top-center" });
          onUpdateSpace(updatedSpace.updatedSpace);
        } catch (error) {
          console.error(error);
          toast.error('Internal Server Error', { position: "top-center" });
        }
      };



      const handleUpdateSpaceDescription = async () => {
        try {
          // Validate formData.spaceDescription
          if (typeof formData.spaceDescription !== 'string') {
            toast.error('Invalid spaceDescription');
            return;
          }
    
          const response = await fetch(`${serverRoute}/api/space/updateSpaceDescription/${spaceData._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              newSpaceDescription: formData.spaceDescription,
            }),
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.error || 'Failed to update spaceDescription', { position: "top-center" });
            return;
          }
    
          const updatedSpace = await response.json();
          toast.success('SpaceDescription updated successfully', { position: "top-center" });
          onUpdateSpace(updatedSpace.updatedSpace);
        } catch (error) {
          console.error(error);
          toast.error('Internal Server Error', { position: "top-center" });
        }
      };


      const handleUpdateSpaceSquareArea = async () => {
        try {
          // Validate formData.spaceSquareArea
          if (typeof formData.spaceSquareArea !== 'string') {
            toast.error('Invalid spaceSquareArea');
            return;
          }
    
          const response = await fetch(`${serverRoute}/api/space/updateSpaceSquareArea/${spaceData._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              newSpaceSquareArea: formData.spaceSquareArea,
            }),
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.error || 'Failed to update spaceSquareArea', { position: "top-center" });
            return;
          }
    
          const updatedSpace = await response.json();
          toast.success('SpaceSquareArea updated successfully', { position: "top-center" });
          onUpdateSpace(updatedSpace.updatedSpace);
        } catch (error) {
          console.error(error);
          toast.error('Internal Server Error', { position: "top-center" });
        }
      };


      const handleUpdateSpaceRules = async () => {
        try {
          // Validate formData.spaceRules
          if (typeof formData.spaceRules !== 'string') {
            toast.error('Invalid spaceRules');
            return;
          }
    
          const response = await fetch(`${serverRoute}/api/space/updateSpaceRules/${spaceData._id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              newSpaceRules: formData.spaceRules,
            }),
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.error || 'Failed to update spaceRules', { position: "top-center" });
            return;
          }
    
          const updatedSpace = await response.json();
          toast.success('SpaceRules updated successfully', { position: "top-center" });
          onUpdateSpace(updatedSpace.updatedSpace);
        } catch (error) {
          console.error(error);
          toast.error('Internal Server Error', { position: "top-center" });
        }
      };
    
  
    return (
      <Form>
        <ToastContainer/>
        <Table striped bordered hover>
          <tbody>
          <tr>
              <td>Space Name Title</td>
              <td>
                <Form.Control
                  type="text"
                  name="spaceNameTitle"
                  value={formData.spaceNameTitle}
                  onChange={handleInputChange}
                />
              </td>
              <td>
              <Button variant="primary" onClick={handleUpdateSpaceNameTitle}>
                  Update
                </Button>
              </td>
            </tr>
            <tr>
              <td>Username</td>
              <td>
                <Form.Control
                  className='w-100'
                  type="text"
                  name="space_username"
                  value={formData.space_username}
                  onChange={handleInputChange}
                />
              </td>
              <td>
              <Button variant="primary" onClick={() => handleSpaceNameUpdateField('space_username')}>
                  Update
                </Button>
              </td>
            </tr>
            <tr>
              <td>Space Type</td>
              <td>
                <Form.Control
                  className='w-100'
                  type="text"
                  name="spaceType"
                  value={formData.spaceType}
                  onChange={handleInputChange}
                  
                />
              </td>
              <td>
              <Button variant="primary" onClick={handleUpdateSpaceType} >
                  Update
                </Button>
              </td>
            </tr>
            <tr>
              <td>Space Description</td>
              <td>
                <TextareaAutosize
                  className="form-control w-100"
                  minRows={3} // Minimum number of rows
                  maxRows={10} // Maximum number of rows
                  name="spaceDescription"
                  value={formData.spaceDescription}
                  onChange={handleInputChange}
                />
              </td>
              <td className='text-centre'>
              <Button variant="primary" onClick={handleUpdateSpaceDescription}>
                  Update
                </Button>
              </td>
            </tr>
            <tr>
              <td>Arrival Instructions</td>
              <td>
                {/* Use TextareaAutosize for the textarea */}
                <TextareaAutosize
                  className="form-control w-100"
                  minRows={3} // Minimum number of rows
                  maxRows={10} // Maximum number of rows
                  name="spaceArrivalInstructions"
                  value={formData.spaceArrivalInstructions}
                  onChange={handleInputChange}
                />
              </td>
              <td className='text-centre'>
                <Button
                  variant="primary"
                  onClick={handleUpdateSpaceArrivalInstructions}
                >
                  Update
                </Button>
              </td>
            </tr>
            <tr>
              <td>Space Square Area</td>
              <td>
                <Form.Control
                  type="text"
                  name="spaceSquareArea"
                  value={formData.spaceSquareArea}
                  onChange={handleInputChange}
                />
                
              </td>
              <td>
              <Button variant="primary" onClick={handleUpdateSpaceSquareArea}>
                  Update
                </Button>
              </td>
            </tr>
            <tr>
              <td>Space Rules</td>
              <td>
               
                 <TextareaAutosize
                  className="form-control w-100"
                  minRows={3} // Minimum number of rows
                  maxRows={10} // Maximum number of rows
                  name="spaceRulesspaceRules"
                  value={formData.spaceRules}
                  onChange={handleInputChange}
                />               
              </td>
              <td>
              <Button variant="primary" onClick={handleUpdateSpaceRules}>
                  Update
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
    );
  };


  export default SpaceForm;