import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Carousel, Table, Card , OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FaStar, FaMapMarkerAlt, FaExpand, FaCalendarCheck, FaCalendarTimes, FaCalendarAlt } from 'react-icons/fa';
import { MdOutlineDashboard } from "react-icons/md";
import { useUser, useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { CiHeart } from "react-icons/ci";
import { FiShare } from "react-icons/fi";
import { FaInfoCircle } from 'react-icons/fa';



import "./details.css";
import ReviewForm from './ReviewForm';
import LoadingComponent from '../utilities/utilities';
import MapContainer from '../utilities/MapContainer';

const EventDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const [eventDetails, setEventDetails] = useState({});
  const [length_space, setlength_space] = useState(0)
  const [eventType, setEventType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [guests, setGuests] = useState('');
  const { isSignedIn, user } = useUser();
  const { userId } = useAuth();
  const [likeData, setLikeData] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [markers, setmarkers] = useState([{
  }]);
  const [mpesaNumber, setMpesaNumber] = useState(''); 
  const [ErrorTableMessage, setErrorTableMessage] = useState('')
  const [locationDetails, setLocationDetails] = useState({
    countyCity: '',
    ward: '',
    areaLocalArea: '',
  });


  // State variables for Calculation
  const [numberOfHours, setNumberOfHours] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  // Reviews
  const [reviews, setReviews] = useState([]);

  // Define the handleReviewSubmit function to handle the submission of the review
  const handleReviewSubmit = async (reviewData) => {

    try {
      const spaceId = eventDetails.space_id; // Replace with the actual space ID

      // Send the review to the server
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/reviews/reviews/${spaceId}`, {
        space_reviews: {
          space_user: userId, // Replace with the actual user ID
          space_reviewer_name: user.fullName, // Replace with the reviewer's name
          space_reviewer_img: user.imageUrl, // Replace with the reviewer's image URL
          space_reviewer_rates: reviewData.rating,
          space_reviewer_comment: reviewData.comment,
          space_reviewer_dataposted: new Date().toISOString(),
        },
      });

      toast.success(`Review submitted successfully: `, { position: "top-center" });

      // Update the state with the new review
       setReviews([...reviews, response.data]);


    } catch (error) {
      // Handle errors
      console.error('Error submitting review:', error.message);
      toast.error('Failed to submit review. Please try again.', { position: "top-center" });
    }
  };


  useEffect(() => {
    const eventDataFromUrl = searchParams.get("data");

    if (eventDataFromUrl) {
      localStorage.setItem("eventData", eventDataFromUrl);

      const fetchDataForSpace = async (spaceId) => {
        try {
          // Make a GET request to the server endpoint for fetching space details
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/api/spaces/details/${spaceId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (response.ok) {
            // If the response is successful, parse the JSON data
            const spaceDetails = await response.json();

            // Do something with the retrieved data (e.g., update UI, etc.)
            setEventDetails(spaceDetails);
            setlength_space(1);
            fetchLikes(spaceDetails);
            fetchAverageRating(spaceDetails);
            fetchLocationDetails(spaceDetails)
            fetchReviews(spaceDetails);
          } else {
            // Handle the case where the server returned an error
            const errorMessage = await response.text();
            console.error(`Failed to fetch space details: ${errorMessage}`);
          }
        } catch (error) {
          // Handle network or other errors
          console.error(`Failed to fetch space details: ${error.message}`);
        }
      };

      fetchMarker(JSON.parse(eventDataFromUrl));

      // Example: Fetch data for a space with a specific ID (replace '123' with the actual space ID)
      fetchDataForSpace(JSON.parse(eventDataFromUrl));
    }
  }, [searchParams]);



  const fetchLocationDetails = async (spaceDetails) => {
    try {
      let space_id = spaceDetails.space_id; // Replace with the actual space ID

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/spaceLocationDetails/LocationDetails/${space_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add other headers if needed
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch location details. Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      setLocationDetails(data.space_location);
    } catch (error) {
      console.log(error.message);
    }
  };

  const LocationSpan = ({ locationDetails }) => {
    if (!locationDetails) {
      return null;
    }
  
    // Assuming locationDetails has properties: city, ward, area
    const { countyCity, ward, areaLocalArea } = locationDetails;
  
    return (
      <span>
        {countyCity && <span>{countyCity}</span>}
        {countyCity && ward && <span> / </span>}
        {ward && <span>{ward}</span>}
        {(countyCity || ward) && areaLocalArea && <span> / </span>}
        {areaLocalArea && <span>{areaLocalArea}</span>}
      </span>
    );
  };


  const fetchLikes = async (spaceDetails) => {
    try {
      let spaceId = spaceDetails.space_id;

      // Fetch data from the server based on the spaceId
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/likes/likes/${spaceId}`,
        {
          method: 'GET', // specify the HTTP method
          headers: {
            'Content-Type': 'application/json', // set the Content-Type header
            // Add any other headers if needed
          },
        }
      );

      // Check if the response is successful
      if (!response.ok) {
        throw new Error('Failed to fetch likes');
      }

      // Parse the response as JSON
      const data = await response.json();

      console.log(data);

      // Update the state with the fetched like data
      setLikeData(data.number_of_likes);
      // Set loading to false as data fetching is complete

    } catch (error) {
      // Log an error message if there's an issue with the fetch
      console.error('Error fetching likes:', error);
      // Set loading to false even if there's an error
    }
  };

  const handleLike = async () => {
    try {
      let spaceId = eventDetails.space_id;

      // Send a POST request to like the space
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/likes/likes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ space_id: spaceId, user_id: userId }),
      });

      if (!response.ok) {
        if (response.status === 400) {
          // User has already liked the space
          toast.warning('You have already liked this space', {
            position: 'top-center',
          });
        } else {
          throw new Error(`Failed to like the space ${response.error}`);
        }
      }

      if (response.ok) {
        // Show a success notification
        toast.success('Liked the space!', {
          position: 'top-center',
        });

        fetchLikes(eventDetails)
      }
    } catch (error) {
      console.error('Error liking the space:', error);

      // Show an error notification
      toast.error(`Failed to like the space ${error}`, {
        position: 'top-center',
      });
    }
  };

  const fetchAverageRating = async (spaceDetails) => {
    try {
      // Extract space_id from spaceDetails
      let spaceId = spaceDetails.space_id;

      // Make a GET request to fetch the average rating for the specified spaceId
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/reviews/average-rating/${spaceId}`);

      // Check if the request was successful (status code 2xx)
      if (!response.ok) {
        // If not successful, throw an error
        throw new Error('Failed to fetch average rating');
      }

      // Parse the response body as JSON
      const data = await response.json();

      // Update the state with the fetched average rating
      setAverageRating(data.average_rating);

    } catch (error) {
      // Handle any errors that occur during the fetch
      console.error('Error fetching average rating:', error);
      // You might want to show an error message to the user or log it in your application
    }
  };

  // Fetch existing space data and populate the form
  const fetchMarker = async (space_id) => {

    // Extract space_id from the eventDetails
    let spaceId = space_id;

    try {

      // Fetch space data from the server using the spaceId
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/spaceLocation/SpaceLocation/${spaceId}`);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error('Failed to fetch space data');
      }

      // Parse the response JSON
      const spaceData = await response.json();
      console.log(spaceData);

      // Update the state with the fetched space data
      setmarkers([{
        title: spaceData.space_title,
        space_type: spaceData.space_type,
        location: spaceData.location,
      }]);

    } catch (error) {

      // Handle errors, log to console for now
      console.error(error);
    }
  };

  // Function to render images for desktop
  const renderDesktopImages = () => {
    try {

      const images = eventDetails.spaceImages || []; // Use an empty array if images is undefined

      const firstImage = images.length > 0 ? images[0] : null;
      const restImages = images.slice(1, 5);

      return (
        <div className='Desktop-render'>
          <div className='first-image'>
            <img className="img-fluid" src={firstImage} alt={`Event Image 1`} />
          </div>
          <div className='rest-images'>
            {restImages.map((image, index) => (
              <img key={index + 1} className="img-fluid" src={image} alt={`Event Image ${index + 2}`} />
            ))}
          </div>
        </div>
      );
    } catch (error) {
      return <></>
    }
  };

  const handleEventTypeChange = (e) => {
    setEventType(e.target.value);

    if (startDate && endDate) {
      calculateTotalPrice(new Date(startDate),new Date(endDate),e.target.value, eventDetails)
   }
 
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);

    if (e.target.value && endDate) {
      calculateTotalPrice(new Date(e.target.value),new Date(endDate),eventType, eventDetails)
   }
 
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);

    if (startDate && e.target.value) {
       calculateTotalPrice(new Date(startDate),new Date(e.target.value),eventType, eventDetails)
    }
  
  };


  const handleGuestsChange = (e) => {
    setGuests(e.target.value);
  };


  // Define the handleMpesaNumberChange event handler
  const handleMpesaNumberChange = (e) => {
    setMpesaNumber(e.target.value);
  };


  const handleBookingSubmit = async (e) => {
    e.preventDefault();

    if (!user || user.fullName == null) {
      // Show an error notification if user or fullName is null
      toast.error('Please create an account or sign in before making a booking', { position: 'top-center' });
      return;
    }

    try {
      const space_id = eventDetails.space_id;
      const space_owner = eventDetails.appUserId;
      const posted_date = new Date();
      const Space_Name = eventDetails.spaceNameTitle;
      const space_booking_details = {
        name_of_booker: user.fullName,
        img_of_booker: user.imageUrl,
        booking_start_date: startDate,
        booking_end_date: endDate,
        number_of_guests: guests,
        mpesa_number : mpesaNumber
      };
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/booking/bookings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ space_id, space_booking_details, space_owner, posted_date, Space_Name }),
      });

      if (!response.ok) {
        // If the response is not ok, throw an error with the status and status text
        const errorData = await response.json();
        toast.error(`Error creating booking: ${errorData.message}`, { position: 'top-center' });
        throw new Error(`${response.status} - ${response.statusText}\n${JSON.stringify(errorData)}`);
      }

      const responseData = await response.json();
      console.log(responseData);

      // Show success notification using Toastify
      toast.success('Booking created successfully', { position: 'top-center' });
    } catch (error) {
      // Show error notification using Toastify
      toast.error(`Error creating booking: ${error.message}`, { position: 'top-center' });
      console.error('Error creating booking:', error);
    }
  };


// Function to render images for mobile as a carousel
const renderMobileImageCarousel = () => {
  const images = eventDetails.spaceImages || "";
  const imageHeight = "320px"; // Set the desired fixed height for the images

  return (
    <div className='Mobile-view'>
      <Carousel className="">
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={image} alt={`Event Image ${index + 1}`} style={{ height: imageHeight }} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};


  const SpaceRulesCard = ({ text, maxLength }) => {
    const [showFullText, setShowFullText] = useState(false);

    const truncatedText = showFullText ? text : `${text.slice(0, maxLength)} . . .`;

    const toggleShowFullText = () => {
      setShowFullText(!showFullText);
    };

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title><h2>Space Rules</h2></Card.Title>
          <hr />
          <Card.Text className='description-para'>

            <div className='description-para'>{truncatedText}</div>
            {text.length > maxLength && (
              <button className='read-more-button' onClick={toggleShowFullText}>
                {showFullText ? 'Read Less' : 'Read More'}
              </button>
            )}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };


  const PricingTable = ({ spaceActivity }) => {
    const renderTableRows = () => {
      return Object.keys(spaceActivity).map((activityType, index) => {
        const { pricing, numPeople, discount, whoCanBook, Amenities, NumPeople } = spaceActivity[activityType];

        return pricing ? (
          <tr key={index}>
            <td>{activityType}</td>
            <td>ksh. {pricing}</td>
            <td>{discount ? `${discount}%` : '-'}</td>
            <td>{numPeople} hrs</td>
            <td>{whoCanBook}</td>
            <td>{NumPeople}</td>
            {/* Add more columns as needed */}
          </tr>
        ) : null;
      });
    };

    return (
      <Table striped bordered hover responsive className="mt-4">
        <thead>
          <tr>
            <th>Service</th>
            <th>Hourly Price</th>
            <th>Discount</th>
            <th>Minimum Number of Hours</th>
            <th>Who Can Book instantly</th>
            <th>Max Number of People</th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </Table>
    );
  };


  const OpeningHours = ({ openingHours }) => {
    // Transform openingHours object into the desired array structure

    const hoursData = Object.entries(openingHours).map(([day, details]) => ({
      day,
      availability: details.isOpen ? (details.hoursType === "allDay") ? "Full Day" : `Partially  ` : 'Not Available', // Adjust as needed
    }));

    return (
      <AvailableHoursTable hoursData={hoursData} openingHours={openingHours} />
    );
  };


  const AvailableHoursTable = ({ hoursData, openingHours }) => {

    // Helper function to format time in AM/PM format
    const formatTime = (timeString) => {
      const time = new Date(`2000-01-01T${timeString}`);
      return time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    };


    return (
      <Table striped bordered hover responsive className="mt-4">
        <thead>
          <tr>
            <th>Day</th>
            <th>Availability</th>
          </tr>
        </thead>
        <tbody>
          {hoursData.map((day, index) => (
            <tr key={index}>
              <td>{day.day.charAt(0).toUpperCase() + day.day.slice(1)}</td>
              <td>
                {day.availability.includes('Full Day') && (
                  <span>
                    <FaCalendarCheck className="text-success" /> Full Day
                  </span>
                )}
                {day.availability.includes('Partially') && (
                  <span>
                    <FaCalendarAlt className="text-warning" /> Partially  {
                      openingHours[day.day].hoursType === "sethours" ?
                        `${formatTime(openingHours[day.day].hours.startDate)} - ${formatTime(openingHours[day.day].hours.endDate)}`
                        : ""
                    }
                  </span>
                )}
                {day.availability === 'Not Available' && (
                  <span>
                    <FaCalendarTimes className="text-danger" /> Not Available
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };



  const AmenitiesTable = ({ spaceActivity }) => {
    // Extract amenities from the spaceActivity object
    const amenitiesSet = new Set();

    // Loop through each activity type (Meeting, Events, MediaProduction, etc.)
    Object.values(spaceActivity).forEach((activity) => {
      // Loop through amenities of each activity
      activity.Amenities && activity.Amenities.forEach((amenity) => {
        amenitiesSet.add(amenity);
      });
    });

    // Convert the set to an array
    const amenitiesArray = Array.from(amenitiesSet);

    return (
      <Table striped bordered hover responsive className="mt-4">
        <thead>
          <tr>
            <th>Category</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Amenities</td>
            <td>
              <ul>
                {amenitiesArray.map((amenity, index) => (
                  <li>{amenity}</li>
                ))}
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };


  const fetchReviews = async (spaceDetails) => {
    try {
      let spaceId = spaceDetails.space_id;
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/reviews/reviews/${spaceId}`);
      setReviews(response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error.message);
    }
  };

  const ReviewsSection = ({ spaceId }) => {
   

    return (
      <Container className="mt-4 mb-5">
        <h2>Guest Reviews</h2>
        {reviews.length === 0 ? (
          <p>No reviews yet.</p>
        ) : (
          <Row xl={1} sm={1} md={1} lg={1} className="g-4">
            {reviews.map((spaceReview, index) => (
              <Col key={index}>
                <Card className="review-card">
                  <Card.Body>
                    {spaceReview.space_reviews.map((review, reviewIndex) => (
                      <div key={reviewIndex}>
                        <div className="d-flex align-items-center mb-3 ml-4">
                          <img
                            src={review.space_reviewer_img}
                            alt={`${review.space_reviewer_name}'s Profile`}
                            className="profile-image-review mr-3"
                          />
                          <div>
                            <h5>{review.space_reviewer_name}</h5>
                            <p>
                              <FaCalendarAlt /> {new Date(review.space_reviewer_dataposted).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                        <p>{review.space_reviewer_comment}</p>
                        <div className="star-rating">
                          {[...Array(review.space_reviewer_rates)].map((_, i) => (
                            <FaStar key={i} className="text-warning m-1" />
                          ))}
                        </div>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    );
  };

  const ShortenedDescription = ({ text, maxLength }) => {
    const [showFullText, setShowFullText] = useState(false);

    const truncatedText = showFullText ? text : `${text.slice(0, maxLength)} . . .`;

    const toggleShowFullText = () => {
      setShowFullText(!showFullText);
    };

    return (
      <div className='inner-descriptions'>
        <div className='description-para'>{truncatedText}</div>
        {text.length > maxLength && (
          <div className='read-more-button' onClick={toggleShowFullText}>
            {showFullText ? 'Read Less' : 'Read More'}
          </div>
        )}
      </div>
    );
  };

  // Function to calculate total price
  const calculateTotalPrice = (checkInTime, checkOutTime, spaceType, eventDetails) => {

     // Check if spaceType is not selected
     if (!eventDetails.spaceActivity[spaceType]) {
      setErrorTableMessage('Please select Event type !!')
      console.error('Event type is not selected');
      return null;
    }

     // Check if checkOutTime is greater than checkInTime
     if (checkOutTime <= checkInTime) {
      console.error('Check-out time should be greater than check-in time');
      setErrorTableMessage('Check-out time should be greater than check-in time')
      
      return null;
    }

    // Validate inputs
    if (!(checkInTime instanceof Date)) {
      console.error('Invalid check-in time for calculateTotalPrice');
      setErrorTableMessage('Invalid check-in time');
      return null;
    }

    if (!(checkOutTime instanceof Date)) {
      console.error('Invalid check-out time for calculateTotalPrice');
      setErrorTableMessage('Invalid check-out time');
      return null;
    }

    if (!spaceType) {
      console.error('Space type is not selected for calculateTotalPrice');
      setErrorTableMessage('Please select Event type !!');
      return null;
    }

    if (!eventDetails) {
      console.error('Event details are missing for calculateTotalPrice');
      setErrorTableMessage('Event details are missing');
      return null;
    }


   

    // Convert check-in and check-out times into hours
    const reservationHours = Math.floor((checkOutTime - checkInTime) / (60 * 60 * 1000));

    // Update numberOfHours state
    setNumberOfHours(reservationHours);

    // Get pricing details for the selected space type
    const selectedSpace = eventDetails.spaceActivity[spaceType];
    const spacePrice = parseFloat(selectedSpace?.pricing) || 0;
    const spaceDiscount = parseFloat(selectedSpace?.discount) || 0;

    // Update price and discount state
    setPrice(spacePrice);
    setDiscount(spaceDiscount);

    // Calculate total price
    let calculatedTotalPrice = reservationHours * spacePrice;

    // Apply discount if present
    if (spaceDiscount > 0) {
      const discountAmount = (spaceDiscount / 100) * calculatedTotalPrice;
      calculatedTotalPrice -= discountAmount;
    }

    // Update totalPrice state
    setTotalPrice(calculatedTotalPrice.toFixed(0));

    setErrorTableMessage("")



    // Round the total price to 2 decimal places
    return calculatedTotalPrice.toFixed(2);
  };

  const EstimatedCalculationTable = ({ numberOfHours, price, discount, totalPrice }) => {
    // Check if any of the required props is missing or has incorrect types

    if (ErrorTableMessage.length > 1) {

      return (
        <>
          <hr />
          <p className='text-danger'>{ErrorTableMessage}</p>
        </>
      );

      
    }

    // Convert numberOfHours to days
    const numberOfDays = Math.floor(numberOfHours / 24);
    const remainingHours = numberOfHours % 24;
  
    return (
      <>
        <hr />
        <p>Summary Calculation</p>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Days</th>
              <th>Hours</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{numberOfDays} days {remainingHours} hrs</td>
              <td>{numberOfHours} hrs</td>
              <td>ksh.{price}</td>
              <td>{discount}%</td>
              <td>ksh.{new Intl.NumberFormat('en-KE').format(totalPrice)}</td>
            </tr>
          </tbody>
        </Table>
      </>
    );
  };;


  return (

    length_space > 0 ?

      <div>
        <ToastContainer />
        <Container className="mt-4">
          <h2>{eventDetails.title}</h2>
          <div className="event-details-icons-section">
            {/* Left side (visible in all sizes) */}
            <div className="event-details-icons-section-left">
              <span className="event-star" ><FaStar className='text-warning' /> <span className='rating-number'>{averageRating}</span></span>
              <FaMapMarkerAlt className="event-location text-primary" /> {eventDetails.type} <LocationSpan locationDetails={locationDetails} />
            </div>

            {/* Right side (visible in all sizes) */}
            <div className="event-details-icons-section-right"> {/* Hide on smaller screens */}
              <span className='share-icon-event'>
                <>
                  <FiShare className='share-icon-icon btn-share-icon' /> <span>share </span>
                </>
              </span>
              <span size="sm">
                <CiHeart className="text-danger bg-white btn-share-icon" onClick={handleLike} />  {likeData}
              </span>
            </div>
          </div>

          {/* Render desktop or mobile images based on screen size */}
          {renderDesktopImages()}
          {renderMobileImageCarousel()}
        </Container>
        <Container className="mt-4 mb-5">
          <Row>
            {/* First column with host information */}
            <Col md={7}>
              {/* Description Details  */}
              <div className='event-details-conationer-desc'>
                <div className='head-section-details'>
                  <div>
                    <h3 className='hoster-name-intro'>{eventDetails.spaceNameTitle} Hosted By {eventDetails.space_username}</h3>
                    <div className='hoster-event-details'>
                      <MdOutlineDashboard /> {eventDetails.type}
                      <FaExpand className='space-left' /> {eventDetails.spaceSquareArea} Sq ft
                    </div>
                  </div>
                  <img alt="Host" src={eventDetails.space_userimage} className="avator-event-image" />
                </div>
                <div className='body-section-details'></div>
              </div>
              <hr />

              <div className='conatiner-event-inner-details'>
                <div className='inner-descriptions'>
                  <ShortenedDescription text={eventDetails.spaceDescription} maxLength={300} />
                </div>
              </div>
              <hr />
              <h2>Pricing</h2>
              {/* Start Price table  */}
              {<PricingTable spaceActivity={eventDetails.spaceActivity || ""} />}
              <hr />
              <h2>Available Hours</h2>
              {<OpeningHours openingHours={eventDetails.spaceAvailable || ""} />}
              <hr />
              <h2>Space Amenities</h2>
              <AmenitiesTable spaceActivity={eventDetails.spaceActivity || {}} />

              <SpaceRulesCard text={eventDetails.spaceRules} maxLength={300} />
              <hr />

              <Card className="mt-4">
                <Card.Body>
                  <Card.Title><h2>Space Cancellation Policy :{eventDetails.spaceCancellation?.name} </h2></Card.Title>
                  <hr />
                  <Card.Text className='description-para'>
                    {eventDetails.spaceCancellation?.description}
                  </Card.Text>

                </Card.Body>
              </Card>
              <hr />
              <ReviewsSection spaceId={eventDetails.space_id} />
            </Col>

            {/* Second column with form */}
            <Col md={5}>
              <ToastContainer />
              <Form onSubmit={handleBookingSubmit} className="border p-4 rounded request-booking-form">
                <div className='Event-price'>
                  <span className='price-head'>{eventDetails.spaceType}  : Booking Form <span className='perhour'></span></span>
                  <div>
                    <span className="event-star" ><FaStar className='text-warning' /> {averageRating}</span>
                  </div>
                </div>
                <hr />
                <Form.Group controlId="eventType" className="mb-1">
                  <Form.Label>Event Type</Form.Label>
                  <Form.Control as="select" value={eventType} onChange={handleEventTypeChange} className="w-100 mb-4">
                      <option value="" disabled>Select an activity</option> {/* Add this line for the default option */}
                      {Object.keys(eventDetails.spaceActivity).map((activityType) => {
                        const { pricing } = eventDetails.spaceActivity[activityType];

                        // Include activity type in dropdown only if pricing has a value and it's not empty
                        if (pricing && pricing.trim() !== "") {
                          return (
                            <option key={activityType} value={activityType}>
                              {activityType}
                            </option>
                          );
                        }
                        return null; // Exclude activity type if pricing is empty
                      })}
                  </Form.Control>
                </Form.Group>

                <hr />
                <Form.Group controlId="reserveDateTime mt-2 mb-3">
                  <Form.Label>Reserve Date & Time</Form.Label>
                  <div className="grid-dates">
                    <span className='start-date'>
                      <span className='start-date-label'> Check In</span>
                      <Form.Control type="datetime-local" required value={startDate} onChange={handleStartDateChange} className='w-30' />
                    </span>
                    <span className='end-date'>
                      <span className='start-date-label'>Check Out</span>
                      <Form.Control type="datetime-local" required value={endDate} onChange={handleEndDateChange} />
                    </span>
                  </div>
                </Form.Group>
                
                <EstimatedCalculationTable
                      numberOfHours={numberOfHours}
                      price={price}
                      discount={discount}
                      totalPrice={totalPrice}
                    />
                <hr />
                <Form.Group controlId="guests" className='mt-4'>
                  <Form.Label>Guests</Form.Label>
                  <Form.Control type="number" value={guests} onChange={handleGuestsChange} className='w-100 mb-3' required placeholder="Enter number of Guest"  min={0}/>
                </Form.Group>

                {/* New Form Group for M-Pesa Number */}
                <Form.Group controlId="mpesaNumber" className='mt-4'>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="mpesaTooltip">{"Important Notice: Upon the host's acceptance of your booking request, you will be prompted to proceed with the payment for the reserved space using the M-Pesa payment method. Kindly be prepared to complete this transaction to secure your booking successfully."}</Tooltip>}
                      >
                        <Form.Label>
                          M-Pesa Number{' '}
                          <span className="info-icon">
                            <FaInfoCircle />
                          </span>
                        </Form.Label>
                      </OverlayTrigger>
                      <Form.Control
                        type="tel"
                        value={mpesaNumber}
                        onChange={handleMpesaNumberChange}
                        placeholder="Enter your M-Pesa number"
                        className='w-100 mb-3'
                        required
                      />
                    </Form.Group>


                <Button variant="primary" type="submit" className='w-100 mt-4' >
                  Request To Book
                </Button>
              </Form>


              <hr></hr>
              {markers && (<MapContainer markers={markers} />)}

              <hr></hr>
              {isSignedIn && userId !== eventDetails.appUserId ? (
                // User is authenticated, show the ReviewForm
                <ReviewForm onSubmit={(reviewData) => handleReviewSubmit(reviewData)} />
              ) : (
                // User is not authenticated, you can redirect them to the login page or show a message
                <p></p>
              )}
            </Col>
          </Row>
        </Container>

      </div>

      :

      <Container>
        <LoadingComponent />
      </Container>

  );
};

export default EventDetailsPage;
