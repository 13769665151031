// src/pages/Recommendation.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Recommendation.css'; // Import the custom CSS file
import { Link } from 'react-router-dom';

import AllEventsPage from '../pages/AllList';
import Recom from '../pages/Recom';


const Recommendation = () => {
  return (
    <Container className="recommendation-container">
      <div className='organize-headers'>
      <h1 className="recommendation-head">Recommended for you</h1>
      <p>Explore a selection of creative listings</p>
      </div>
      <Link to="/all-spaces" className="see-all-link">
          <div> See All</div>
        </Link>
       <Recom/>
    </Container>
  );
};

export default Recommendation;
