// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css'; // Import the custom CSS file

const Footer = () => {
  return (
    <footer className="custom-footer bg-light p-4">
      <Container className='footer-conatiner'>
        <Row>
          {/* About */}
        
          <Col xs={12} sm={6} md={3}>
            <h5>About</h5>
            <ul className="list-unstyled">
              <li><a href="/">Privacy Policy</a></li>
              <li><a href="/">Terms of Service</a></li>
              <li><a href="/">Blogs</a></li>
              <li><a href="/">Contact Us</a></li>
            </ul>
          </Col>

          {/* Host */}
          <Col xs={12} sm={6} md={3}>
            <h5>Host</h5>
            <ul className="list-unstyled">
              <li><a href="/">Privacy Policy</a></li>
              <li><a href="/">Responsible Hosting</a></li>
              <li><a href="/">Trust and Safety</a></li>
            </ul>
          </Col>

          {/* Community */}
          <Col xs={12} sm={6} md={3}>
            <h5>Community</h5>
            <ul className="list-unstyled">
              <li><a href="/">Diversity & Belonging</a></li>
            </ul>
          </Col>

          {/* Contact */}
          <Col xs={12} sm={6} md={3}>
            <h5>Contact</h5>
            <ul className="list-unstyled">
              <li><a href="/">Terms of Service</a></li>
              <li><a href="/">Blogs</a></li>
              <li><a href="/">Contact Us</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
