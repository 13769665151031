import React, {useState, useEffect} from 'react';
import { createSearchParams,Link, useNavigate } from 'react-router-dom';
import { Card, Carousel, Row, Col } from 'react-bootstrap';
import { FaUsers, FaBed, FaExpand, FaDollarSign } from 'react-icons/fa';
import './eventcard.css';

const EventSpaceCard = ({ data }) => {
  const [spaceDetails, setSpaceDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const {
    spaceType,
    spaceNameTitle,
    spaceDescription,
    spaceImages,
    space_id,
  } = data;

  


  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
    
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/spaces/details/${space_id}`);

        if (!response.ok) {
          throw new Error(`Failed to fetch space details: ${response.statusText}`);
        }
    
        const data = await response.json();
    
        setSpaceDetails(data);
    
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    
    

    fetchSpaceDetails();
  }, [space_id]);


  const truncateDescription = (description, wordCount) => {
    const words = description.split(' ');
    if (words.length > wordCount) {
      return words.slice(0, wordCount).join(' ') + ' . . .';
    }
    return description;
  };

  const truncatedDescription = truncateDescription(spaceDescription, 8);


  

  const handleCard = () => {
    navigate({
        pathname: `/eventdetails`,
        search:
        createSearchParams({
        data: JSON.stringify(space_id)
        }).toString()
    });

  }

  return (
     <Col>
        <Card className="event-space-card mb-5 mr-3"  >
        <Carousel interval={null}>
            {spaceImages.map((image, index) => (
              <Carousel.Item key={index}>
                <img className="d-block w-100 event-image" src={image} alt={`Event Space ${index + 1}`} />
              </Carousel.Item>
            ))}
          </Carousel>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <small>{spaceType}</small>
            <div className="rate-icons">
              {/* {Array.from({ length: rate }, (_, index) => (
                // <FaDollarSign key={index} />
              ))} */}
            </div>
          </div>
          <Card.Title>{spaceNameTitle}</Card.Title>
          <Card.Text className='desc-para-card'>{truncatedDescription}</Card.Text>
          <button className='btn btn-success w-100 mt-2' onClick={handleCard}> Book Space</button>
        </Card.Body>
      </Card>
     </Col>
  );
};

export default EventSpaceCard;
