import React, { useState, useEffect, useCallback } from 'react';
import { ProgressBar, Container, Row, Col, Form, Button, Table, Card } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import MapSelect from '../utilities/MapSelect';
import "../components/createSpaceForms/location.css";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { IoShieldCheckmarkOutline, IoCalendar } from "react-icons/io5";
import { MdDiscount } from "react-icons/md";
import { RiUserFill } from "react-icons/ri";

import { v4 as uuidv4 } from 'uuid';
import 'react-toastify/dist/ReactToastify.css';

import { useUser } from "@clerk/clerk-react";
import { useNavigate } from 'react-router-dom';

import './createspace.css'



import { useDropzone } from 'react-dropzone';
import { BsImage } from 'react-icons/bs';


const MAX_IMAGE_SIZE = 0.5 * 1024 * 1024; // 0.5MB


const CreateSpacePage = () => {

  // ======================================
  //      Create space States 
  // =====================================
  const [step, setStep] = useState(1);
  const [userName, setUserName] = useState('');
  const [uniqueId, setUniqueId] = useState('');

  // User data
  const { user } = useUser();

  // Next Blocker 

  const [nextBlocker, setnextBlocker] = useState(false)

  const handleNextStep = () => {
    if (step < 2 && handleSubmit()) {
      setStep(step + 1);
      window.scrollTo(0, 0); // Scroll to the top
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
      window.scrollTo(0, 0); // Scroll to the top
    }
  };



  // Navigation
  const navigate = useNavigate();



  // ======================================
  //      Create space UseEffect
  // =====================================
  useEffect(() => {
    const generatedId = uuidv4();
    setUniqueId(generatedId);
    localStorage.setItem('space_Id', generatedId);
    localStorage.setItem('space_username', user.firstName);
    localStorage.setItem('space_userimage', user.imageUrl);
    localStorage.setItem('appUserId', user.id);

  }, []);


  // ======================================
  //      Create space Handles
  // =====================================

  function formatDataFromLocalStorage() {

    if(validation2()){

      const localStorageItemNames = [
        'space_Id',
        'appUserId',
        'spaceType',
        'Meeting',
        'healthSafetyFormData',
        'spaceImages',
        'spaceCancellation',
        'spaceParkingOption',
        'spaceCity',
        'space_username',
        'spaceAgeLimit',
        'spaceWifiDetails',
        'operatingHours',
        'spaceBillingAddress',
        'space_userimage',
        'spaceNameTitle',
        'spaceDescription',
        'spaceAddressDetail',
        'spaceRules',
        'spaceSecurityCamera',
        'spacePostalCode',
        'space',
        'spaceSquareArea',
        'spaceArrivalInstructions',
        'MediaProduction',
        'spaceAvailable',
        'Events',
        'Meeting',
      ];
  
      const formattedData = {
        appUserId: '',
        space_id: '',
        space_username: '',
        space_userimage: '',
        spaceType: '',
        spaceAddressDetail: '',
        spaceCity: '',
        spaceBillingAddress: '',
        spacePostalCode: '',
        spaceParkingOption: '',
        spaceSecurityCamera: '',
        spaceNameTitle: '',
        spaceDescription: '',
        spaceSquareArea: '',
        spaceRules: '',
        spaceAgeLimit: '',
        spaceWifiDetails: '',
        spaceArrivalInstructions: '',
        spaceImages: '',
        spaceAvailable: '',
        healthSafetyFormData: '',
        spaceCancellation: '',
        spaceActivity: {
          Meeting: '',
          Events: '',
          MediaProduction: '',
        },
  
      };
  
  
  
      localStorageItemNames.forEach((itemName) => {
        try {
          const itemValue = localStorage.getItem(itemName)
  
          if (itemValue) {
            switch (itemName) {
              case 'appUserId':
                formattedData.appUserId = itemValue;
                break;
              case 'space_Id':
                formattedData.space_id = itemValue;
                break;
              case 'space_username':
                formattedData.space_username = itemValue;
                break;
              case 'space_userimage':
                formattedData.space_userimage = itemValue;
                break;
              case 'spaceType':
                formattedData.spaceType = itemValue;
                break;
              case 'spaceAddressDetail':
                formattedData.spaceAddressDetail = JSON.parse(itemValue);
                break;
              case 'spaceCity':
                formattedData.spaceCity = itemValue;
                break;
              case 'spacePostalCode':
                formattedData.spacePostalCode = itemValue;
                break;
              case 'spaceParkingOption':
                formattedData.spaceParkingOption = JSON.parse(itemValue);
                break;
              case 'spaceSecurityCamera':
                formattedData.spaceSecurityCamera = JSON.parse(itemValue);
                break;
              case 'spaceNameTitle':
                formattedData.spaceNameTitle = itemValue;
                break;
              case 'spaceDescription':
                formattedData.spaceDescription = itemValue;
                break;
              case 'spaceSquareArea':
                formattedData.spaceSquareArea = itemValue;
                break;
              case 'spaceRules':
                formattedData.spaceRules = itemValue;
                break;
              case 'spaceAgeLimit':
                formattedData.spaceAgeLimit = itemValue;
                break;
              case 'spaceWifiDetails':
                formattedData.spaceWifiDetails = JSON.parse(itemValue);
                break;
              case 'spaceArrivalInstructions':
                formattedData.spaceArrivalInstructions = itemValue;
                break;
              case 'spaceImages':
                formattedData.spaceImages = JSON.parse(itemValue);
                break;
              case 'spaceAvailable':
                formattedData.spaceAvailable = JSON.parse(itemValue);
                break;
              case 'spaceCancellation':
                formattedData.spaceCancellation = JSON.parse(itemValue);
                break;
              case 'Meeting':
                formattedData.spaceActivity.Meeting = JSON.parse(itemValue);
                break;
              case 'Events':
                formattedData.spaceActivity.Events = JSON.parse(itemValue);
                break;
              case 'MediaProduction':
                formattedData.spaceActivity.MediaProduction = JSON.parse(itemValue);
                break;
              default:
                break;
            }
          }
  
        } catch (error) {
          // Handle the error (e.g., set a default value)
          console.error(`Error parsing item ${itemName}: ${error.message}`);
        };
  
      })
  
      const handleSubmitLocation = async () => {
  
        let location = JSON.parse(localStorage.getItem("location"))
        let space_id = localStorage.getItem("space_Id")
        let space_title = localStorage.getItem("spaceNameTitle")
        let space_type = localStorage.getItem("spaceType")
  
        let formData = {
          space_id: space_id,
          space_title: space_title,
          space_type: space_type,
          location: location
        }
  
        try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/spaceLocation/SpaceLocation`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
  
          if (response.ok) {
            // Server successfully processed the request
            const data = await response.json();
  
            // Handle the data based on your needs (e.g., update state, show a success message)
            console.log('Data submitted successfully:', data);
  
          } else {
            // Handle other response statuses if needed
            console.error(`Failed to submit data: ${response.statusText}`);
          }
  
        } catch (error) {
          // Handle network errors or other issues
          console.error('Error submitting data:', error.message);
        }
      };
  
      const handleSubmitLocationDetails = async () => {
  
        let space_id = localStorage.getItem("space_Id")
        let space_location = JSON.parse(localStorage.getItem("locationDetail"))
        console.log({ space_id, space_location });
        try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/spaceLocationDetails/LocationDetails`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ space_id, space_location }),
          });
  
          if (!response.ok) {
            throw new Error(`Failed to add LocationDetails: ${response.statusText}`);
          }
  
          const newLocationDetails = await response.json();
          console.log('New LocationDetails added:', newLocationDetails);
          // You can perform additional actions after successfully adding the LocationDetails
  
        } catch (error) {
          console.error('Error adding LocationDetails:', error.message);
          // Handle the error or display a message to the user
        }
      };
  
  
      const submitDataToServer = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/spaces`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formattedData),
          });
  
  
          if (response.ok) {
            // Server successfully processed the request
            toast.success('Data submitted successfully!', { position: 'top-center' });
  
            // Set a timeout to redirect after 2000 milliseconds (2 seconds)
            setTimeout(() => {
              // Redirect to the dashboard
              navigate('/hostDashbord');
            }, 2000);

            // Clear all localStorage items except "clerk-db-jwt"
            clearLocalStorageExceptJWT();
  
          } else {
            // Server returned an error
            const errorMessage = await response.text();
            toast.error(`Failed to submit data: ${errorMessage}`, { position: 'top-center' });
          }
        } catch (error) {
          // Network or other errors
          toast.error(`Failed to submit data: ${error.message}`, { position: 'top-center' });
        }
      };
  
      handleSubmitLocationDetails();
      handleSubmitLocation();
      submitDataToServer();


    }
   

  }

  function clearLocalStorageExceptJWT() {
    const jwtToken = localStorage.getItem('clerk-db-jwt');
    localStorage.clear();
    localStorage.setItem('clerk-db-jwt', jwtToken);
  }
  


  // space listing form

  const [spaceType, setSpaceType] = useState('');
  const [hasParking, setHasParking] = useState(false);
  const [parkingOptions, setParkingOptions] = useState({
    free_Street_Parking: false,
    nearby_Parking_Lot: false,
    free_Onsite_Parking: false,
    metered_Street_Parking: false,
    paid_Onsite_Parking: false,
    valet: false,
  });
  const [parkingDescription, setParkingDescription] = useState('');
  const [hasSecurityCameras, setHasSecurityCameras] = useState(false);
  const [cameraDescription, setcameraDescription] = useState('');

  let labels = {
    free_Street_Parking: "Free Street Parking",
    nearby_Parking_Lot: "Nearby Parking Lot",
    free_Onsite_Parking: "Free Onsite Parking",
    metered_Street_Parking: "Metered Street Parking",
    paid_Onsite_Parking: "Paid Onsite Parking",
    valet: "valet",
  }

  const handleParkingOptionChange = (option) => {
    setParkingOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };


  // Space  Details Form
  const [spaceTitle, setSpaceTitle] = useState('');
  const [spaceDescription, setSpaceDescription] = useState('');
  const [spaceSize, setSpaceSize] = useState('');
  const [houseRules, setHouseRules] = useState('');
  const [allowedAge, setAllowedAge] = useState('allAges');
  const [wifiName, setWifiName] = useState('');
  const [wifiPassword, setWifiPassword] = useState('');
  const [arrivalInstructions, setArrivalInstructions] = useState('');
  const [words, setwords] = useState(0)
  const [reached, setreached] = useState(false)

  const checkWordCount = (description) => {
    const wordCount = description.split(/\s+/).filter((word) => word.length > 0).length;
    setwords(wordCount)

    // Display a toast if the word count reaches 280
    if (wordCount < 20) {
      setreached(false)
    }
    if (wordCount >= 20) {
      setreached(true)
    }

    // Change color when the word count reaches 280
    const colorClass = wordCount >= 20 ? 'reached-word-limit' : '';


    return { wordCount, colorClass };
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setSpaceDescription(newDescription);

    // Check word count and color when the description changes
    const { wordCount, colorClass } = checkWordCount(newDescription);

    // Set color class dynamically
    e.target.className = `w-100 mb-4 mt-2 border-green`;
  };


  const [formDataLocation, setFormDataLocation] = useState({
    countyCity: '',
    ward: '',
    areaLocalArea: '',
  });

  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    setFormDataLocation((prevData) => ({ ...prevData, [name]: value }));
  };



  // Images
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isImageSectionOpen, setIsImageSectionOpen] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
   // Store uploaded images in local storage as base64 strings
  const base64Images = uploadedImages.map((image) => `data:image/jpeg;base64,${image.base64}`);
  localStorage.setItem('spaceImages', JSON.stringify(base64Images));
  }, [uploadedImages]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const processedImages = [];
  
    for (const file of acceptedFiles) {
      // Check if the file has an image file extension
      if (isImageFile(file)) {
        const fileSize = file.size;
  
        if (fileSize > MAX_IMAGE_SIZE) {
          const base64 = await processImage(file);
          processedImages.push({ base64, file });
        } else {
          const base64 = await convertFileToBase64(file);
          processedImages.push({ base64, file });
        }
      } else {
        // Display an error toast for non-image files
        toast.error(`${file.name} is not an image file. Please upload only images.`, { position: 'top-center' });
      }
    }
  
    setUploadedImages((prevImages) => [...prevImages, ...processedImages]);
    setProgress(0); // Reset progress after processing is complete
  
    toast.success('Images uploaded successfully!', { position: 'top-center' });
  }, []);
  
  const isImageFile = (file) => {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'image/jpg'];
    return acceptedImageTypes.includes(file.type);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const processImage = async (file) => {
    const dimensions = await getImageDimensions(file);
    const maxWidth = 800; // Adjust the maximum width as needed
    const resizedImage = await resizeImage(file, maxWidth, dimensions);
    const base64 = await convertFileToBase64(resizedImage);
    return base64;
  };

  const getImageDimensions = async (file) => {
    const response = await fetch(URL.createObjectURL(file));
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(blob);
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = reject;
    });
  };

  const resizeImage = async (file, maxWidth, dimensions) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = maxWidth;
    canvas.height = (maxWidth / dimensions.width) * dimensions.height;

    const image = new Image();
    image.src = URL.createObjectURL(file);

    await new Promise((resolve) => {
      image.onload = () => {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        resolve();
      };
    });

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(new File([blob], file.name, { type: file.type, lastModified: Date.now() }));
      }, file.type, 0.8); // Adjust the quality as needed
    });
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.readAsDataURL(file);
    });
  };




  // Operating hours 

  // State to manage operating hours for each day
  const [days, setDays] = useState({
    monday: { isOpen: true, hoursType: 'allDay', hours: { start: '06:00', end: '18:00' } },
    tuesday: { isOpen: true, hoursType: 'allDay', hours: { start: '06:00', end: '18:00' } },
    wednesday: { isOpen: true, hoursType: 'allDay', hours: { start: '06:00', end: '18:00' } },
    thursday: { isOpen: true, hoursType: 'allDay', hours: { start: '06:00', end: '18:00' } },
    friday: { isOpen: true, hoursType: 'allDay', hours: { start: '06:00', end: '18:00' } },
    saturday: { isOpen: true, hoursType: 'allDay', hours: { start: '06:00', end: '18:00' } },
    sunday: { isOpen: true, hoursType: 'allDay', hours: { start: '06:00', end: '18:00' } },
  });


  useEffect(() => {
    // Store operating hours in local storage
    localStorage.setItem('spaceAvailable', JSON.stringify(days));
  }, [days]);

  const handleDayToggle = (day) => {
    setDays((prevDays) => ({
      ...prevDays,
      [day]: { ...prevDays[day], isOpen: !prevDays[day].isOpen },
    }));
    localStorage.setItem('spaceAvailable', JSON.stringify(days));
  };

  const handleHoursTypeToggle = (day, type) => {
    setDays((prevDays) => ({
      ...prevDays,
      [day]: { ...prevDays[day], hoursType: type },
    }));
  };

  const handleHoursChange = (day, field, value) => {
    setDays((prevDays) => ({
      ...prevDays,
      [day]: { ...prevDays[day], hours: { ...prevDays[day].hours, [field]: value } },
    }));
    localStorage.setItem('spaceAvailable', JSON.stringify(days));
  };



  // Canclation

  const [selectedPolicy, setSelectedPolicy] = useState(false);

  const policies = [
    {
      name: 'Very Flexible',
      description:
        'Guests may cancel their Booking until 24 hours before the event start time and will receive a full refund (including all Fees) of their Booking Price. Bookings cancellations submitted less than 24 hours before the Event start time are not refundable.',
    },
    {
      name: 'Flexible',
      description:
        'Guests may cancel their Booking until 7 days before the event start time and will receive a full refund (including all Fees) of their Booking Price. Guests may cancel their Booking between 7 days and 24 hours before the event start time and receive a 50% refund (excluding Fees) of their Booking Price. Booking cancellations submitted less than 24 hours before the Event start time are not refundable.',
    },
    {
      name: 'Standard 30 day',
      description:
        'Guests may cancel their Booking until 30 days before the event start time and will receive a full refund (including all Fees) of their Booking Price. Guests may cancel their Booking between 30 days and 7 days before the event start time and receive a 50% refund (excluding Fees) of their Booking Price. Cancellations submitted less than 7 days before the Event start time are not refundable.',
    },
    {
      name: 'Standard 90 day',
      description:
        'Guests may cancel their Booking until 90 days before the event start time and will receive a full refund (including all Fees) of their Booking Price. Guests may cancel their Booking between 90 days and 14 days before the event start time and receive a 50% refund (excluding Fees) of their Booking Price. Cancellations submitted less than 14 days before the Event start time are not refundable.',
    },
  ];

  const handlePolicyChange = (policy) => {
    setSelectedPolicy(policy);
  };



  const [keepConversations, setKeepConversations] = useState(false);
  const [processPayments, setProcessPayments] = useState(false);
  const [followPolicies, setFollowPolicies] = useState(false);
  const [meetRegulations, setMeetRegulations] = useState(false);
  const [CommissionPol, setCommissionPol] = useState(false);

  const handleCheckboxChange = (checkboxName) => {
    switch (checkboxName) {
      case 'keepConversations':
        setKeepConversations(!keepConversations);
        break;
      case 'processPayments':
        setProcessPayments(!processPayments);
        break;
      case 'followPolicies':
        setFollowPolicies(!followPolicies);
        break;
      case 'meetRegulations':
        setMeetRegulations(!meetRegulations);
        break;
      case 'CommissionPol':
        setCommissionPol(!meetRegulations);
        break;
      default:
        break;
    }
  };


  // Activity

  const [meetingEnabled, setMeetingEnabled] = useState(false);
  const [eventEnabled, setEventEnabled] = useState(false);
  const [mediaProductionEnabled, setMediaProductionEnabled] = useState(false);
  const [isPricingEnabled, setPricingEnabled] = useState(false);


  // Meeting states 
  const [hourlyRate, setHourlyRate] = useState('');
  const [minimumHours, setMinimumHours] = useState('');
  const [discountFor8Hours, setDiscountFor8Hours] = useState('');
  const [instantBooking, setInstantBooking] = useState('');
  const [capacity, setCapacity] = useState('');
  const [amenities, setAmenities] = useState([]);
  const [customAmenity, setCustomAmenity] = useState('');


  // Event states
  const [hourlyRateEvent, setHourlyRateEvent] = useState('');
  const [minimumHoursEvent, setMinimumHoursEvent] = useState('');
  const [discountFor8HoursEvent, setDiscountFor8HoursEvent] = useState('');
  const [instantBookingEvent, setInstantBookingEvent] = useState('');
  const [capacityEvent, setCapacityEvent] = useState('');
  const [amenitiesEvent, setAmenitiesEvent] = useState([]);
  const [customAmenityEvent, setCustomAmenityEvent] = useState('');


  // Media states
  const [hourlyRateMedia, setHourlyRateMedia] = useState('');
  const [minimumHoursMedia, setMinimumHoursMedia] = useState('');
  const [discountFor8HoursMedia, setDiscountFor8HoursMedia] = useState('');
  const [instantBookingMedia, setInstantBookingMedia] = useState('');
  const [capacityMedia, setCapacityMedia] = useState('');
  const [amenitiesMedia, setAmenitiesMedia] = useState([]);
  const [customAmenityMedia, setCustomAmenityMedia] = useState('');

  const amenityList = [
    'WiFi',
    'Tables',
    'Chairs',
    'Whiteboard',
    'Chalkboard',
    'Projector',
    'Screen',
    'Flip Charts',
    'Monitor',
    'Conference Phone',
    'Parking Space(s)',
    'Public Transportation',
    'Restrooms',
    'Wheelchair Accessible',
    'Breakout Space',
    'Kitchen',
    'Coffee',
    'Apple TV',
    'Printer',
    'Rooftop',
    'Outdoor Area',
  ];


  const handleAmenityChange = (amenity) => {
    // Toggle amenity in the state
    setAmenities((prevAmenities) =>
      prevAmenities.includes(amenity)
        ? prevAmenities.filter((a) => a !== amenity)
        : [...prevAmenities, amenity]
    );
  };


  const handleAmenityChangeevent = (amenity) => {
    // Toggle amenity in the state
    setAmenitiesEvent((prevAmenities) =>
      prevAmenities.includes(amenity)
        ? prevAmenities.filter((a) => a !== amenity)
        : [...prevAmenities, amenity]
    );
  };


  const handleAmenityChangemedia = (amenity) => {
    // Toggle amenity in the state
    setAmenitiesMedia((prevAmenities) =>
      prevAmenities.includes(amenity)
        ? prevAmenities.filter((a) => a !== amenity)
        : [...prevAmenities, amenity]
    );
  };

  const handleAddAmenity = () => {
    // Add custom amenity to the state
    if (customAmenity.trim() !== '') {
      setAmenities((prevAmenities) => [...prevAmenities, customAmenity.trim()]);
      setCustomAmenity('');
    }
  };

  const handleFormSubmit = () => {

    // Validation checks
    if (!hourlyRate || !minimumHours || !instantBooking || !capacity) {
      toast.error('Please fill in all required fields.', { position: 'top-center' });
      return;
    }

    if (isNaN(hourlyRate) || isNaN(minimumHours) || isNaN(capacity)) {
      toast.error('Please enter valid numeric values for pricing, minimum hours, and capacity.', { position: 'top-center' });
      return;
    }

    if (amenities.length === 0 || !amenities.includes('WiFi') || !amenities.includes('Chairs') || !amenities.includes('Tables')) {
      toast.error('Please select WiFi, Chairs, and Tables as amenities.', { position: 'top-center' });
      return;
    }
    // Save form data to local storage
    let Meeting = {
      pricing: hourlyRate,
      numPeople: minimumHours,
      discount: discountFor8Hours,
      whoCanBook: instantBooking,
      NumPeople: capacity,
      Amenities: amenities,
    };
    localStorage.setItem('Meeting', JSON.stringify(Meeting));
    toast.success("Meeting Setting update successfully", { position: "top-center" })
  };


  const handleFormSubmitMedia = () => {

    // Validation checks
    if (!hourlyRateMedia || !minimumHoursMedia || !instantBookingMedia || !capacityMedia) {
      toast.error('Please fill in all required fields.', { position: 'top-center' });
      return;
    }

    if (isNaN(hourlyRateMedia) || isNaN(minimumHoursMedia) || isNaN(capacityMedia)) {
      toast.error('Please enter valid numeric values for pricing, minimum hours, and capacity.', { position: 'top-center' });
      return;
    }

    if (amenitiesMedia.length === 0) {
      toast.error('Please select At leaset One  amenities.', { position: 'top-center' });
      return;
    }
    // Save form data to local storage
    let Media = {
      pricing: hourlyRateMedia,
      numPeople: minimumHoursMedia,
      discount: discountFor8HoursMedia,
      whoCanBook: instantBookingMedia,
      NumPeople: capacityMedia,
      Amenities: amenitiesMedia,
    };
    localStorage.setItem('MediaProduction', JSON.stringify(Media));
    toast.success("Meeting Setting update successfully", { position: "top-center" })
  };

  const handleFormSubmitEvent = () => {

    // Validation checks
    if (!hourlyRateEvent || !minimumHoursEvent || !instantBookingEvent || !capacityEvent) {
      toast.error('Please fill in all required fields.', { position: 'top-center' });
      return;
    }

    if (isNaN(hourlyRateEvent) || isNaN(minimumHoursEvent) || isNaN(capacityEvent)) {
      toast.error('Please enter valid numeric values for pricing, minimum hours, and capacity.', { position: 'top-center' });
      return;
    }

    if (amenitiesEvent.length === 0 ) {
      toast.error('Please select At leaset One  amenities.', { position: 'top-center' });
      return;
    }
    // Save form data to local storage
    let Event = {
      pricing: hourlyRateEvent,
      numPeople: minimumHoursEvent,
      discount: discountFor8HoursEvent,
      whoCanBook: instantBookingEvent,
      NumPeople: capacityEvent,
      Amenities: amenitiesEvent,
    };
    localStorage.setItem('Events', JSON.stringify(Event));
    toast.success("Event Setting update successfully", { position: "top-center" })
  };





  // Handler submit Form

  const handleSubmit = (e) => {

    // Handle form submission logic here
    if (validateForm()) {
      // If validation passes, proceed with storing data

      // spaceParkingOption: {
      //   spaceParkingOption: Boolean,
      //   spaceParkingOptionDescription: String,
      // },
      // spaceSecurityCamera: {
      //   spaceSecurityCamera: Boolean,
      //   spaceSecurityCameraDescription: String,
      // },

      // Store spaceType in localStorage
      localStorage.setItem('spaceType', spaceType);
      let spaceParkingOption = {
        spaceParkingOption: hasParking,
        spaceParkingOptionDescription: parkingDescription,
        free_Street_Parking: parkingOptions["free_Onsite_Parking"],
        nearby_Parking_Lot: parkingOptions["nearby_Parking_Lot"],
        free_Onsite_Parking: parkingOptions["free_Onsite_Parking"],
        metered_Street_Parking: parkingOptions["metered_Street_Parking"],
        paid_Onsite_Parking: parkingOptions["paid_Onsite_Parking"],
        valet: parkingOptions["valet"],
      }
      localStorage.setItem('spaceParkingOption', JSON.stringify(spaceParkingOption));
      let spaceSecurityCamera = {
        spaceSecurityCamera: hasSecurityCameras,
        spaceSecurityCameraDescription: cameraDescription

      }
      localStorage.setItem('spaceSecurityCamera', JSON.stringify(spaceSecurityCamera));



      // Validate word count in spaceDescription
      if (!checkWordCount(spaceDescription)) {
        return
      }


      // spaceNameTitle: String,
      // spaceDescription: String,
      // spaceSquareArea: String,
      // spaceRules: String,
      // spaceAgeLimit: String,
      // spaceWifiDetails: {
      //   wifi: String,
      //   wifiPassword: String
      // },
      // spaceArrivalInstructions: String,

      localStorage.setItem('spaceNameTitle', spaceTitle);
      localStorage.setItem('spaceDescription', spaceDescription);
      localStorage.setItem('spaceSquareArea', spaceSize);
      localStorage.setItem('spaceRules', houseRules);
      localStorage.setItem('spaceAgeLimit', allowedAge);
      let spaceWifiDetails = {
        wifi: wifiName,
        wifiPassword: wifiPassword
      }
      localStorage.setItem('spaceWifiDetails', JSON.stringify(spaceWifiDetails));
      localStorage.setItem('spaceArrivalInstructions', arrivalInstructions);

      // Handle form submission logic here
      localStorage.setItem("locationDetail", JSON.stringify(formDataLocation))


      return true;

    }

    return false;


  };

  const validateForm = () => {
    // Check if spaceType is not empty
    if (!spaceType.trim()) {
      toast.error('Please enter the type of space', { position: 'top-center' });
      return false;
    }

    // Check if parking is selected
    if (hasParking === true) {
      // Check if parking description is not empty
      if (!parkingDescription.trim()) {
        toast.error('Please enter the parking description', { position: 'top-center' });
        return false;
      }

      // Check if at least one parking option is selected
      const selectedOptions = Object.values(parkingOptions);
      if (!selectedOptions.some((option) => option === true)) {
        toast.error('Please select at least one parking option', { position: 'top-center' });
        return false;
      }
    }


    // Check if any field is empty
    const fields = [
      { label: 'Space Title', value: spaceTitle },
      { label: 'Space Description', value: spaceDescription },
      { label: 'Space Size', value: spaceSize },
      { label: 'House Rules', value: houseRules },
      { label: 'Allowed Age', value: allowedAge },
      { label: 'Arrival Instructions', value: arrivalInstructions },
    ];

    const emptyFields = fields.filter((field) => !field.value.trim());

    if (emptyFields.length > 0) {
      // Display error toast with the names of empty fields
      const emptyFieldNames = emptyFields.map((field) => field.label).join(', ');
      toast.error(`Please fill in all required fields: ${emptyFieldNames}`, { position: 'top-center' });
      return false;
    }


    // location Form data
    // Check if any field is empty
    const emptyFieldsLocation = Object.keys(formDataLocation).filter((key) => !formDataLocation[key]);
    if (emptyFieldsLocation.length > 0) {
      toast.error(`Please fill in the following fields: ${emptyFieldsLocation.join(', ')}`, { position: "top-center" });
      return false;
    }


    let locationInMap = localStorage.getItem("location");

    // Validate if locationInMap is not null and not undefined
    if (locationInMap) {
      // Parse the JSON string from localStorage
      const parsedLocation = JSON.parse(locationInMap);

      // Validate if lat and lng are present in the parsed location
      if (parsedLocation && parsedLocation.lat && parsedLocation.lng) {
        // Your logic when location is valid
        console.log("Location retrieved successfully:", parsedLocation);
      } else {
        toast.error(`Please Click on the map where the place is located:`, { position: "top-center" });
        // Handle validation error, e.g., display an error message
        console.error("Error: Latitude and Longitude must be present in the location");
        return false;
      }
    } else {
      // Handle validation error, e.g., display an error message
      toast.error(`Please Click on the map where the place is located:`, { position: "top-center" });
      return false;
    }


    let locaImages = JSON.parse(localStorage.getItem("spaceImages"));

    // Check if locaImages is not null and not an empty array
    if (locaImages && Array.isArray(locaImages) && locaImages.length > 0) {
      // The "spaceImages" in localStorage has values
      // Proceed with your logic here
      console.log("localStorage has values for spaceImages:", locaImages);
    } else {
      // No values in "spaceImages" in localStorage
      console.log("localStorage does not have values for spaceImages");
      toast.error(`Please upload your Images  :`, { position: "top-center" });
      return false;
    }


    if (!selectedPolicy) {
      toast.error('Please select a cancellation policy.', { position: 'top-center' });
      return false;
    }

    try {
      // Save the selected policy to local storage
      localStorage.setItem('spaceCancellation', JSON.stringify(selectedPolicy));
    } catch (error) {
      console.error('Error saving cancellation policy to local storage:', error);
      toast.error('An error occurred while saving the cancellation policy. Please try again.', { position: 'top-center' });
    }

    // Add more validation logic as needed
    return true;
  };


  const validation2 = () => {
   
    // Assuming event, MediaProduction, and Meeting are JSON objects
    let event = JSON.parse(localStorage.getItem('Event')) || {};
    let mediaProduction = JSON.parse(localStorage.getItem('MediaProduction')) || {};
    let meeting = JSON.parse(localStorage.getItem('Meeting')) || {};

    // Check if at least one object has attributes with values
    const isAnyObjectValid = Object.values({
      ...event,
      ...mediaProduction,
      ...meeting,
    }).some((value) => value !== null && value !== undefined && value !== '');

    if (isAnyObjectValid) {
      // At least one object has attributes with values
      console.log('At least one object has attributes with values:', { event, mediaProduction, meeting });
    } else {
      // No object has attributes with values
      console.log('No object has attributes with values');
      toast.error('PLease select At least one activities', { position: 'top-center' });
      return false;
    }


    // Validation: Check if all checkboxes are checked
    if (!(keepConversations && processPayments && followPolicies && meetRegulations)) {
      toast.error('Please agree to all policies before submitting.', { position: "top-center" });
      return false;
    }

    return true;

  }


  return (
    <Container className="mt-5">
      <ToastContainer/>

      {step === 1 && (
        <>
          <CreateSpaceComponent step={step} updateStep={setnextBlocker} />

          {/* <SpaceAddressForm /> */}

          <Container className="mt-5 container-size">
            <ToastContainer />
            <div className='Event-price text-center mb-5'>
              <span className='price-head'> Space Listing Form </span>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="spaceType">
                <h2>What type of space are you listing?</h2>
                <Form.Label>Enter the type of space that most closely represents the physical space being listed</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the type of space"
                  value={spaceType}
                  onChange={(e) => setSpaceType(e.target.value)}
                  required
                  className='w-100 mb-5 mt-2'
                />
              </Form.Group>

              <Form.Group controlId="hasParking">
                <h2>Describe the parking options </h2>
                <Form.Label>Are there parking options at or near your space?</Form.Label>
                <Row className="mb-3">
                  <Col>
                    <Form.Check
                      type="radio"
                      label="Yes"
                      value={true}
                      checked={hasParking === true}
                      onChange={() => setHasParking(true)}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      label="No"
                      value={false}
                      checked={hasParking === false}
                      onChange={() => {
                        setHasParking(false);
                        setParkingOptions({
                          free_Street_Parking: false,
                          nearby_Parking_Lot: false,
                          free_Onsite_Parking: false,
                          metered_Street_Parking: false,
                          paid_Onsite_Parking: false,
                          valet: false,
                        });
                      }}
                    />
                  </Col>
                </Row>

                {hasParking && (
                  <>
                    <Form.Label>Select all that apply</Form.Label>
                    <Row className="mb-5">
                      {Object.entries(parkingOptions).map(([key, label]) => (
                        <Col key={key} md={6}>
                          <Form.Check
                            type="checkbox"
                            label={labels[key]}
                            checked={parkingOptions[key]}
                            onChange={() => handleParkingOptionChange(key)}
                          />
                        </Col>
                      ))}
                    </Row>

                    <Form.Group controlId="parkingDescription" className="mb-3 mt-5">
                      <h4 className='mb-2'> Write a description of the parking options</h4>
                      <Form.Label> Don't include private information. This will be shown publicly.</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="How many cars can fit? How difficult is street parking? How close are nearby lots etc?"
                        value={parkingDescription}
                        onChange={(e) => setParkingDescription(e.target.value)}
                        required
                        className='w-100 mt-2'
                      />
                    </Form.Group>
                  </>
                )}
              </Form.Group>

              <Form.Group controlId="hasSecurityCameras">
                <h3 className='mt-5 mb-3'>Security cameras and recording devices</h3>
                <Form.Label>Does the space have security cameras or recording devices?</Form.Label>
                <Row className="mb-3">
                  <Col>
                    <Form.Check
                      type="radio"
                      label="Yes"
                      value={true}
                      checked={hasSecurityCameras === true}
                      onChange={() => setHasSecurityCameras(true)}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      label="No"
                      value={false}
                      checked={hasSecurityCameras === false}
                      onChange={() => setHasSecurityCameras(false)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              {hasSecurityCameras && (
                <Form.Group controlId="cameraDescription" className="mb-3 mt-5">
                  <h4 className='mb-2'> Write a description of the camera options</h4>
                  <Form.Label> Don't include private information. This will be shown publicly.</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    placeholder="How many cameras are there? Location of the cameras?"
                    value={parkingDescription}
                    onChange={(e) => setcameraDescription(e.target.value)}
                    required
                    className='w-100 mt-2'
                  />
                </Form.Group>
              )}
            </Form>
          </Container>

          <Container className="mt-5 container-size">
           
            <div className='Event-price text-center mb-5'>
              <span className='price-head'> Space Details Form </span>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="spaceTitle" className='mb-5'>
                <h2>Give your space a title</h2>
                <Form.Label>Enter your space title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your space title"
                  value={spaceTitle}
                  onChange={(e) => setSpaceTitle(e.target.value)}
                  required
                  className='w-100 mb-4 mt-2'
                />
              </Form.Group>

              <Form.Group controlId="spaceDescription" className=''>
                <h2>Enter your space description</h2>
                <Form.Label>Minimum 20 words</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter your space description"
                  value={spaceDescription}
                  onChange={handleDescriptionChange}
                  minLength={20}
                  required
                  className='w-100 mb-4 mt-2'
                />
              </Form.Group>
              <div className={reached ? "space-descritions-words mb-5 descritions-green" : "space-descritions-words mb-5"}><span></span><span>current words: {words}</span></div>

              <Form.Group controlId="spaceSize" className='mb-5'>
                <h2>How big is the space guests can book?</h2>
                <Form.Label>Enter the size of the space (in sq ft)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the size of the space"
                  value={spaceSize}
                  onChange={(e) => setSpaceSize(e.target.value)}
                  required
                  className='w-100 mb-4 mt-2'
                />
              </Form.Group>

              <Form.Group controlId="houseRules" className='mb-5'>
                <h2>Enter your house rules</h2>
                <Form.Label>Minimum 50 words</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter your house rules"
                  value={houseRules}
                  onChange={(e) => setHouseRules(e.target.value)}
                  minLength={20}
                  required
                  className='w-100 mb-4 mt-2'
                />
              </Form.Group>

              <Form.Group controlId="allowedAge" className='mb-5'>
                <h2>Who's allowed in your space?</h2>
                <Form.Label>Typically, only venues that serve alcohol have age requirements.</Form.Label>
                <Form.Select
                  value={allowedAge}
                  onChange={(e) => setAllowedAge(e.target.value)}
                  required
                  className='w-100 mb-4 mt-2'
                >
                  <option value="allAges">All ages</option>
                  {/* Loop to generate age options */}
                  {[...Array(21)].map((_, index) => (
                    <option key={index + 1} value={`Ages ${index + 1}+`}>{`Ages ${index + 1}+`}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="wifiDetails" className='mb-5'>
                <h2>What’s your wifi name and password?</h2>
                <Form.Label>Make it easy for your guests to get online by sharing your Wi-Fi information. (Optional)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Wi-Fi Name"
                  value={wifiName}
                  onChange={(e) => setWifiName(e.target.value)}
                  className='w-100 mb-2 mt-2'
                />
                <Form.Control
                  type="password"
                  placeholder="Wi-Fi Password"
                  value={wifiPassword}
                  onChange={(e) => setWifiPassword(e.target.value)}
                  className='w-100 mb-4 mt-2'
                />
              </Form.Group>

              <Form.Group controlId="arrivalInstructions" className='mb-5'>
                <h2>Provide arrival instructions</h2>
                <Form.Label>Help your guests and their attendees find and enter your space.</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter arrival instructions"
                  value={arrivalInstructions}
                  onChange={(e) => setArrivalInstructions(e.target.value)}
                  required
                  className='w-100 mb-4 mt-2'
                />
              </Form.Group>

            </Form>
          </Container>

          <>
            <div className='Event-price text-center mb-5'>
              <span className='price-head'> Location Form </span>
            </div>
            <div className='location-grid'>
              <Form >
                <Form.Group controlId="countyCity" className='mb-4'>
                  <Form.Label>County/City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter county/city"
                    name="countyCity"
                    value={formDataLocation.countyCity}
                    onChange={handleChangeLocation}
                    className='w-100'
                  />
                </Form.Group>

                <Form.Group controlId="ward" className='mb-4'>
                  <Form.Label>Ward</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter ward"
                    name="ward"
                    value={formDataLocation.ward}
                    onChange={handleChangeLocation}
                    className='w-100'
                  />
                </Form.Group>

                <Form.Group controlId="areaLocalArea" className='mb-4'>
                  <Form.Label>Area/Local Area Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter area/local area name"
                    name="areaLocalArea"
                    value={formDataLocation.areaLocalArea}
                    onChange={handleChangeLocation}
                    className='w-100'
                  />
                </Form.Group>
              </Form>
              <MapSelect />
            </div>
          </>

          <div className="image-upload-container">
            <div className='Event-price text-center mb-5'>
              <span className='price-head'> Images Form </span>
            </div>
            
            <div className="photo-info">
              <div className="photo-info">
                <h2>Upload photos of your space</h2>
                <p>
                  Photos are the first thing that guests will see. We recommend adding  or more high-quality photos.
                </p>
                <p>
                  <strong>Photo requirements:</strong>
                </p>
                <ul>
                  <li>High resolution - At least 1,000 pixels wide</li>
                  <li>Horizontal orientation - No vertical photos</li>
                  <li>Color photos - No black & white</li>
                  <li>Misc. - No collages, screenshots, or watermarks</li>
                </ul>
              </div>
            </div>

            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <p>
                <BsImage className="icon" /> Drag and drop some images here, or click to select images
              </p>
              <p>Please add at least 4 space photos</p>
            </div>

            <div className={`image-section ${isImageSectionOpen ? 'open' : ''}`}>
              <div className="uploaded-images">
                {uploadedImages.map((image, index) => (
                  <div key={index} className="image-preview">
                    {progress > 0 && progress < 100 && (
                      <ProgressBar now={progress} label={`${progress.toFixed(2)}%`} />
                    )}
                    <img
                      src={`data:image/jpeg;base64,${image.base64}`}
                      alt={`Preview ${index + 1}`}
                      onLoad={() => setProgress(100)} // Update progress to 100% when the image is loaded
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>


          <>
            <div className='Event-price text-center mb-5 mt-5'>
              <span className='price-head'>What are your operating hours? </span>
            </div>
            <p>Operating hours are the days and hours of the week that your space is open to host bookings (i.e. your general availability).
              Guests will not be able to book times outside of your operating hours. Learn More.</p>
            <div className="operating-hours-form">
              <Form.Group controlId="operatingHours" className="mb-3">
                <Form.Label></Form.Label>
                {Object.entries(days).map(([day, details], index) => (
                  <div key={day}>
                    <Row className="mb-3">
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label={day.charAt(0).toUpperCase() + day.slice(1)}
                          checked={details.isOpen}
                          onChange={() => handleDayToggle(day)}
                        />
                      </Col>
                    </Row>
                    {details.isOpen && (
                      <Row className="mb-3">
                        <Col>
                          <Form.Check
                            type="radio"
                            label="All day (24 hrs)"
                            name={`${day}HoursType`}
                            checked={details.hoursType === 'allDay'}
                            onChange={() => handleHoursTypeToggle(day, 'allDay')}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            type="radio"
                            label="Set hours"
                            name={`${day}HoursType`}
                            checked={details.hoursType === 'setHours'}
                            onChange={() => handleHoursTypeToggle(day, 'setHours')}
                          />
                        </Col>
                        {!details.allDay && details.hoursType === 'setHours' && (
                          <Col>
                            <Row>
                              <Col>
                                <Form.Group controlId={`${day} StartTime`}>
                                  <Form.Control
                                    type="time"
                                    value={details.hours.start}
                                    onChange={(e) => handleHoursChange(day, 'start', e.target.value)}
                                  />
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group controlId={`${day} EndTime`}>
                                  <Form.Control
                                    type="time"
                                    value={details.hours.end}
                                    onChange={(e) => handleHoursChange(day, 'end', e.target.value)}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    )}
                    {index < Object.entries(days).length - 1 && <hr />}
                  </div>
                ))}
              </Form.Group>
            </div>
          </>

          <div>
           
            <div className='Event-price text-center mb-5 mt-5'>
              <span className='price-head'>Choose your Cancellation Policy</span>
            </div>
            <p> Hosts with more flexible cancellation policies attract more bookings.</p>
            <hr />
            <p><b>Cancellation grace period:</b> All Bookings are subject to SpaceYettu’s Grace Period policy which provides a full refund
              for Bookings cancelled within 24 hours from receipt of a Booking Confirmation but no later than 48 hours prior to the Event start time.</p>
            <Form onSubmit={handleSubmit}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th >Name</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody className='calncalletion-policy'>
                  {policies.map((policy, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
                      <td className='td-flex'>
                        <Form.Check
                          type="radio"
                          label=""
                          checked={policy === selectedPolicy}
                          onChange={() => handlePolicyChange(policy)}
                          className='policy-btn'
                        />
                        {policy.name}
                      </td>
                      <td>{policy.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form>
          </div>

        </>

      )}


      {step === 2 && (
        <>
          <div className="activity-selection-container">
            <div className='Event-price text-center mb-5'>
              <span className='price-head'>Which activities would you like to host?</span>
            </div>
            <p>Select the types of activities you would like to host. For each selection, we’ll create a customized listing unique to that activity.</p>
            <div className="container-tip">
              <div className="icon-tip">
                <MdOutlinePrivacyTip className='text-warning' />
              </div>
              <div className="text-tip">
                Tip: To be approved for Meetings your photos must display a meeting or workshop setup including tables and chairs. We also recommend adding photos of your AV setup.
              </div>
            </div>
            <Row>
              <Col md={4}>
                <Card className='mb-5'>
                  <Card.Body>
                    <Card.Title>Meetings</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>Workshops</li>
                        <li>Presentations</li>
                        <li>Retreats</li>
                        <li>and more</li>
                      </ul>
                      <div className={meetingEnabled ? "bg-dark enableBtnBox enableon" : "enableBtnBox"}
                        onClick={() => setMeetingEnabled(!meetingEnabled)}>

                        {meetingEnabled ? (<><IoShieldCheckmarkOutline className='enableicon' /> {" Enabled"} </>) : "Enable"}

                      </div>
                    </Card.Text>
                    {meetingEnabled && (
                      <Row className="mt-4">
                        <ToastContainer />
                        <Col>
                          <Card>
                            <Card.Body>
                              <Card.Title>Pricing</Card.Title>
                              <hr />
                              <Form.Group controlId="hourlyRate" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Hourly rate</Form.Label>
                                <p>Please choose a base hourly rate. You can customize your pricing with attendee pricing, calendar pricing, and add-ons once your listings are created.</p>

                                <div className='input-grid'>
                                  <div className='input-grid-left'>ksh</div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='4500'
                                    min={0} // Set a minimum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a positive number
                                      const value = Math.max(0, parseFloat(e.target.value)) || ''; // Set to empty string if not a valid number
                                      setHourlyRate(value);
                                    }}
                                  /></div>
                                </div>

                              </Form.Group>

                              <Form.Group controlId="minimumHours" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Minimum number of hours</Form.Label>
                                <p>Must be between 1-12 hours</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><IoCalendar /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    className='number-hours'
                                    placeholder='4'
                                    min={1}
                                    max={12}
                                    onChange={(e) => {
                                      // Ensure the entered value is within the range
                                      const value = Math.min(12, Math.max(1, e.target.value));
                                      setMinimumHours(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>

                              <Form.Group controlId="discountFor8Hours" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>8+ hour discount</Form.Label>
                                <p>Encourage guests to book longer by giving a discount for bookings that are 8 hours or more.</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><MdDiscount /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='Optional % off'
                                    min={0} // Set a minimum value if needed
                                    max={100} // Set a maximum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a percentage between 0 and 100
                                      const value = Math.max(0, Math.min(100, parseFloat(e.target.value))) || ''; // Set to empty string if not a valid number
                                      setDiscountFor8Hours(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>
                              <Form.Group controlId="instantBooking" className='mb-4'>
                                <Card.Title>Who can book instantly?</Card.Title>
                                <hr />
                                <Form.Check
                                  type="radio"
                                  name="instantBookingOption"
                                  label="Everyone"
                                  onChange={() => setInstantBooking('Everyone')}
                                  checked={instantBooking === 'Everyone'}
                                />
                                <p>Guests who acknowledge and accept your host rules can book instantly.</p>
                                <Form.Check
                                  type="radio"
                                  name="instantBookingOption"
                                  label="No one"
                                  onChange={() => setInstantBooking('No one')}
                                  checked={instantBooking === 'No one'}
                                />
                                <p>Booking requests will need to be manually accepted or declined.</p>
                              </Form.Group>


                              <Form.Group controlId="capacity" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Capacity</Form.Label>
                                <p>Maximum number of guests</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><RiUserFill /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='250'
                                    min={1} // Set a minimum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a positive integer
                                      const value = Math.max(1, parseInt(e.target.value, 10)) || ''; // Set to empty string if not a valid number
                                      setCapacity(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>

                              <Form.Group controlId="amenities" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Amenities</Form.Label>
                                <p>All amenities you select should be included in your hourly rate. If you have amenities that you charge for, do not include them here. You can add those in a later section.</p>
                                <p>You must include WiFi, tables, and chairs to offer meetings</p>
                                {amenityList.map((amenity) => (
                                  <div key={amenity} className='mb-2'>
                                    <Form.Check
                                      type="checkbox"
                                      label={amenity}
                                      checked={amenities.includes(amenity)}
                                      onChange={() => handleAmenityChange(amenity)}
                                    />
                                  </div>
                                ))}
                              </Form.Group>
                              <Button className='btn btn-primary' type='submit' onClick={handleFormSubmit}>apply</Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className='mb-5'>
                  <Card.Body>
                    <Card.Title>Events</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>Birthdays</li>
                        <li>Networking Events</li>
                        <li>Corporate Parties</li>
                        <li>and more</li>
                      </ul>
                      <div className={eventEnabled ? "bg-dark enableBtnBox enableon" : "enableBtnBox"}
                        onClick={() => setEventEnabled(!eventEnabled)}>
                        {eventEnabled ? (<><IoShieldCheckmarkOutline className='enableicon' /> {" Enabled"} </>) : "Enable"}
                      </div>
                    </Card.Text>
                    {eventEnabled && (
                      <Row className="mt-4">
                        <ToastContainer />
                        <Col>
                          <Card>
                            <Card.Body>
                              <Card.Title>Pricing</Card.Title>
                              <hr />
                              <Form.Group controlId="hourlyRate" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Hourly rate</Form.Label>
                                <p>Please choose a base hourly rate. You can customize your pricing with attendee pricing, calendar pricing, and add-ons once your listings are created.</p>

                                <div className='input-grid'>
                                  <div className='input-grid-left'>ksh</div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='4500'
                                    min={0} // Set a minimum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a positive number
                                      const value = Math.max(0, parseFloat(e.target.value)) || ''; // Set to empty string if not a valid number
                                      setHourlyRateEvent(value);
                                    }}
                                  /></div>
                                </div>

                              </Form.Group>

                              <Form.Group controlId="minimumHours" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Minimum number of hours</Form.Label>
                                <p>Must be between 1-12 hours</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><IoCalendar /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    className='number-hours'
                                    placeholder='4'
                                    min={1}
                                    max={12}
                                    onChange={(e) => {
                                      // Ensure the entered value is within the range
                                      const value = Math.min(12, Math.max(1, e.target.value));
                                      setMinimumHoursEvent(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>

                              <Form.Group controlId="discountFor8Hours" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>8+ hour discount</Form.Label>
                                <p>Encourage guests to book longer by giving a discount for bookings that are 8 hours or more.</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><MdDiscount /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='Optional % off'
                                    min={0} // Set a minimum value if needed
                                    max={100} // Set a maximum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a percentage between 0 and 100
                                      const value = Math.max(0, Math.min(100, parseFloat(e.target.value))) || ''; // Set to empty string if not a valid number
                                      setDiscountFor8HoursEvent(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>
                              <Form.Group controlId="instantBooking" className='mb-4'>
                                <Card.Title>Who can book instantly?</Card.Title>
                                <hr />
                                <Form.Check
                                  type="radio"
                                  name="instantBookingOption"
                                  label="Everyone"
                                  onChange={() => setInstantBookingEvent('Everyone')}
                                  checked={instantBookingEvent === 'Everyone'}
                                />
                                <p>Guests who acknowledge and accept your host rules can book instantly.</p>
                                <Form.Check
                                  type="radio"
                                  name="instantBookingOption"
                                  label="No one"
                                  onChange={() => setInstantBookingEvent('No one')}
                                  checked={instantBookingEvent === 'No one'}
                                />
                                <p>Booking requests will need to be manually accepted or declined.</p>
                              </Form.Group>


                              <Form.Group controlId="capacity" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Capacity</Form.Label>
                                <p>Maximum number of guests</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><RiUserFill /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='250'
                                    min={1} // Set a minimum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a positive integer
                                      const value = Math.max(1, parseInt(e.target.value, 10)) || ''; // Set to empty string if not a valid number
                                      setCapacityEvent(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>

                              <Form.Group controlId="amenities" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Amenities</Form.Label>
                                <p>All amenities you select should be included in your hourly rate. If you have amenities that you charge for, do not include them here. You can add those in a later section.</p>
                                <p>You must include WiFi, tables, and chairs to offer meetings</p>
                                {amenityList.map((amenity) => (
                                  <div key={amenity} className='mb-2'>
                                    <Form.Check
                                      type="checkbox"
                                      label={amenity}
                                      checked={amenitiesEvent.includes(amenity)}
                                      onChange={() => handleAmenityChangeevent(amenity)}
                                    />
                                  </div>
                                ))}
                              </Form.Group>
                              <Button className='btn btn-primary' type='submit' onClick={handleFormSubmitEvent}>apply</Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className='mb-5'>
                  <Card.Body>
                    <Card.Title>Media Productions</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>Film Shoots</li>
                        <li>Photo Shoots</li>
                        <li>Audio Recording</li>
                        <li>and more</li>
                      </ul>
                      <div className={mediaProductionEnabled ? "bg-dark enableBtnBox enableon" : "enableBtnBox"}
                        onClick={() => setMediaProductionEnabled(!mediaProductionEnabled)}>
                        {mediaProductionEnabled ? (<><IoShieldCheckmarkOutline className='enableicon' /> {" Enabled"} </>) : "Enable"}
                      </div>
                    </Card.Text>
                    {mediaProductionEnabled && (
                      <Row className="mt-4">
                        <ToastContainer />
                        <Col>
                          <Card>
                            <Card.Body>
                              <Card.Title>Pricing</Card.Title>
                              <hr />
                              <Form.Group controlId="hourlyRate" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Hourly rate</Form.Label>
                                <p>Please choose a base hourly rate. You can customize your pricing with attendee pricing, calendar pricing, and add-ons once your listings are created.</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'>ksh</div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='4500'
                                    min={0} // Set a minimum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a positive number
                                      const value = Math.max(0, parseFloat(e.target.value)) || ''; // Set to empty string if not a valid number
                                      setHourlyRateMedia(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>
                              <Form.Group controlId="minimumHours" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Minimum number of hours</Form.Label>
                                <p>Must be between 1-12 hours</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><IoCalendar /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    className='number-hours'
                                    placeholder='4'
                                    min={1}
                                    max={12}
                                    onChange={(e) => {
                                      // Ensure the entered value is within the range
                                      const value = Math.min(12, Math.max(1, e.target.value));
                                      setMinimumHoursMedia(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>
                              <Form.Group controlId="discountFor8Hours" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>8+ hour discount</Form.Label>
                                <p>Encourage guests to book longer by giving a discount for bookings that are 8 hours or more.</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><MdDiscount /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='Optional % off'
                                    min={0} // Set a minimum value if needed
                                    max={100} // Set a maximum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a percentage between 0 and 100
                                      const value = Math.max(0, Math.min(100, parseFloat(e.target.value))) || ''; // Set to empty string if not a valid number
                                      setDiscountFor8HoursMedia(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>
                              <Form.Group controlId="instantBooking" className='mb-4'>
                                <Card.Title>Who can book instantly?</Card.Title>
                                <hr />
                                <Form.Check
                                  type="radio"
                                  name="instantBookingOption"
                                  label="Everyone"
                                  onChange={() => setInstantBookingMedia('Everyone')}
                                  checked={instantBookingMedia === 'Everyone'}
                                />
                                <p>Guests who acknowledge and accept your host rules can book instantly.</p>
                                <Form.Check
                                  type="radio"
                                  name="instantBookingOption"
                                  label="No one"
                                  onChange={() => setInstantBookingMedia('No one')}
                                  checked={instantBookingMedia === 'No one'}
                                />
                                <p>Booking requests will need to be manually accepted or declined.</p>
                              </Form.Group>
                              <Form.Group controlId="capacity" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Capacity</Form.Label>
                                <p>Maximum number of guests</p>
                                <div className='input-grid'>
                                  <div className='input-grid-left'><RiUserFill /></div>
                                  <div className='input-grid-right'><input
                                    type="number"
                                    placeholder='250'
                                    min={1} // Set a minimum value if needed
                                    onChange={(e) => {
                                      // Ensure the entered value is a positive integer
                                      const value = Math.max(1, parseInt(e.target.value, 10)) || ''; // Set to empty string if not a valid number
                                      setCapacityMedia(value);
                                    }}
                                  /></div>
                                </div>
                              </Form.Group>

                              <Form.Group controlId="amenities" className='mb-4'>
                                <Form.Label className='modal_lstng_label'>Amenities</Form.Label>
                                <p>All amenities you select should be included in your hourly rate. If you have amenities that you charge for, do not include them here. You can add those in a later section.</p>
                                {amenityList.map((amenity) => (
                                  <div key={amenity} className='mb-2'>
                                    <Form.Check
                                      type="checkbox"
                                      label={amenity}
                                      checked={amenitiesMedia.includes(amenity)}
                                      onChange={() => handleAmenityChangemedia(amenity)}
                                    />
                                  </div>
                                ))}
                              </Form.Group>
                              <Button className='btn btn-primary' type='submit' onClick={handleFormSubmitMedia}>apply</Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          <div className="SpaceYetu-policies-container">
            <h2>Please review the following SpaceYetu policies</h2>

            
            <Form onSubmit={handleSubmit}>

            <Form.Group controlId="CommissionPol">
                <Form.Check
                  type="checkbox"
                  label="SpaceYettu commison policy of every book space"
                  checked={CommissionPol}
                  onChange={() => handleCheckboxChange('CommissionPol')}
                />
                <p>15% platform commission applies to all transactions..</p>
              </Form.Group>

              <Form.Group controlId="keepConversations">
                <Form.Check
                  type="checkbox"
                  label="Keep conversations on SpaceYetu"
                  checked={keepConversations}
                  onChange={() => handleCheckboxChange('keepConversations')}
                />
                <p>Keep conversations with guests on the platform so everyone knows what was agreed to.</p>
              </Form.Group>

              <Form.Group controlId="processPayments">
                <Form.Check
                  type="checkbox"
                  label="Use SpaceYetu to process payments"
                  checked={processPayments}
                  onChange={() => handleCheckboxChange('processPayments')}
                />
                <p>All payments must be processed on SpaceYetu and honor our service fee. All payouts will be made via direct deposit to your bank account.</p>
              </Form.Group>

              <Form.Group controlId="followPolicies">
                <Form.Check
                  type="checkbox"
                  label="Follow the booking, cancellation, and overtime policies"
                  checked={followPolicies}
                  onChange={() => handleCheckboxChange('followPolicies')}
                />
                <p>All bookings are covered by the SpaceYetu Services Agreement. Contracts that conflict with these policies are not allowed.</p>
              </Form.Group>

              <Form.Group controlId="meetRegulations">
                <Form.Check
                  type="checkbox"
                  label="Make sure my space meets local regulations"
                  checked={meetRegulations}
                  onChange={() => handleCheckboxChange('meetRegulations')}
                />
                <p>Follow local regulations to ensure the safety of your guests, yourself, and your space.</p>
              </Form.Group>
            </Form>
          </div>
        </>
      )}

      <hr />
      <div className="mt-4 mb-5 space-btn">
        <Button variant="secondary" onClick={handlePreviousStep} disabled={step === 1}>
          Previous
        </Button>
        {step < 2 ? (
          <Button variant="primary" className="ms-2" onClick={handleNextStep} disabled={nextBlocker === true}>
            Next
          </Button>
        ) : (
          <Button variant="success" className="ms-2" onClick={formatDataFromLocalStorage}>
            Start Hosting
          </Button>
        )}

      </div>
    </Container>
  );
};




const CreateSpaceComponent = (props) => {
  return (
    <Container>
      <Row className="mt-5 Fisrt-slide-host">
        {/* First Column */}
        <h2>Earn money as a SpaceYettu host</h2>
        <p className="text-muted mb-5">Join thousands of hosts renting their space for meetings, events, and film and photo shoots.</p>
      </Row>
      <ToastContainer />
    </Container>
  );
};



export default CreateSpacePage;



