// src/components/ActivitySection.js
import React from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './ActivitySection.css'; // Import the custom CSS file
import space1 from "../images/BanquetHall.jpg";
import space2 from "../images/ProductRelease.jpg";
import space3 from "../images/CorporateEvent.jpg";
import space4 from "../images/TeamMeeting.jpg";
import space5 from "../images/JobFair.jpg";
import space6 from "../images/DanceParty.jpg";
import space7 from "../images/Reception.jpg";
import space8 from "../images/BirthdayParty.jpg";



const activityData = [
  { id: 1, name: 'Banquet Hall', image: space1 },
  { id: 2, name: 'Product Release', image: space2 },
  { id: 3, name: 'Corporate Event', image: space3 },
  { id: 4, name: 'Team Meeting', image: space4 },
  { id: 5, name: 'Job Fair', image: space5 },
  { id: 6, name: 'Dance Party', image: space6 },
  { id: 7, name: 'Reception', image: space7 },
  { id: 8, name: 'Birthday Party', image: space8 },

];

const ActivitySection = () => {
  return (
    <div className="activity-section">
      <Container>
        <h1 className="activity-section-head" >Book Unique Space For Your Activities</h1>
        <Row >
          {activityData.map((activity) => (
            <Col key={activity.id} xs={6} md={5} lg={3}>
              <OverlayTrigger
                key={activity.id}
                placement="bottom"
                overlay={<Tooltip id={`tooltip-${activity.id}`}>{activity.name}</Tooltip>}
              >
                <div className="activity-item" style={{ backgroundImage: `url(${activity.image})` }}>
                  <div className="activity-name">{activity.name}</div>
                </div>
              </OverlayTrigger>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ActivitySection;
