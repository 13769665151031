// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import NavbarComponent from './components/NavbarComponent';
import Home from './components/Home';
import Search from './components/Search';
import "./App.css"
import Footer from './components/Footer';
import AllEventsPage from './pages/AllList';
import EventDetailsPage from './components/EventDetailsPage';
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  SignIn,
  SignUp,
} from "@clerk/clerk-react";

import CreateSpacePage from './pages/CreateSpace';
import Dashboard from './pages/Dashboard';
import HostNavBar from './components/Dashbaord/HostNav';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;



function ClerkProviderWithRoutes() {

  
  const navigate = useNavigate();

  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}>
      <Routes>
        <Route path="/" element={<div><NavbarComponent /><Home /><Footer /></div>} />
        <Route path="/search" element={<div><NavbarComponent /><Search /><Footer /></div>} />
        <Route path="/signin" element={<div><NavbarComponent /><div className='signup-container-route'><SignIn redirectUrl={"/"} routing="path" path="/signin" /></div><Footer /></div>} />
        <Route path="/signup" element={<div><NavbarComponent /><div className='signup-container-route'><SignUp redirectUrl={"/signin"} routing="path" path="/signup" /></div><Footer /></div>} />
        <Route path="/all-spaces" element={<div><NavbarComponent /><AllEventsPage filterEvent={true} /><Footer /></div>} />
        <Route path="/eventdetails" element={<div><NavbarComponent /><EventDetailsPage /><Footer /></div>} />
        <Route path="/become-host" element={
          <><SignedIn><NavbarComponent/><CreateSpacePage /><Footer /></SignedIn>
          <SignedOut >
            <RedirectToSignIn/>
          </SignedOut>
          </>
        } />
        <Route path="/hostDashbord" element={
          <><SignedIn><HostNavBar/> <Dashboard/></SignedIn>
          <SignedOut >
            <RedirectToSignIn/>
          </SignedOut>
          </>
        } />
      </Routes>
    </ClerkProvider>
  )

}


function App() {

  return (
    <Router>
      <ClerkProviderWithRoutes />
    </Router>
  );
}

export default App;
